import { useEffect, useState } from 'react';
import { syncResize } from '@/helpers';

const DESIGN_HEIGHT = 1080;
const MAX_ZOOM = 1.25;

const useLayoutZoom = () => {
  const [zoom, setZoom] = useState('1');

  useEffect(() => {
    const onResize = () => {
      const currentHeight = window.innerHeight;
      const ratio = currentHeight / DESIGN_HEIGHT;
      const newZoom = Math.min(MAX_ZOOM, ratio);

      setZoom(newZoom.toFixed(2));
    };

    onResize();
    syncResize.subscribe(onResize);

    return () => {
      syncResize.unsubscribe(onResize);
    };
  }, []);

  return zoom;
};

export default useLayoutZoom;
