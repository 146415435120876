import { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';
import icons from '@/components/common/icons';
import { experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';
import { MDASH } from '@/helpers';
import { useCagingSettingsContext } from '@/hooks/runDesign/useCagingSettingsContext';

import RunDesignTable from '@/components/runDesign/RunDesignTable';
import styles from './CageSettingsInfoModal.module.scss';
import { ADVANCED_SETTINGS } from '../../constants';
import { getValidationInfo, prepareValidationConfigForAdvancedSettings } from '../EditSettings/helpers';

const cn = classnames.bind(styles);

type CageSettingsInfoModalProps = {
  animationType?: 'slide-animation' | 'splash-animation';
};

const CageSettingsInfoModal: FC<CageSettingsInfoModalProps> = ({ animationType }) => {
  const globalCagingSettings = useSelector(experimentRunDesignSelectors.selectGlobalCagingSettings);
  const designData = useSelector(experimentRunDesignSelectors.selectData);
  const { isCagingSettingsModalOpen, selectedSetting, handleCagingSettingsModalClose } = useCagingSettingsContext();

  const cageSettingsInputValidationConfig = prepareValidationConfigForAdvancedSettings(undefined, globalCagingSettings);

  const settingsList = useMemo(
    () => ADVANCED_SETTINGS.filter((setting) => setting?.withWiki).toSorted((a) => (a.editable ? -1 : 1)),
    [globalCagingSettings]
  );

  return (
    <Modal
      isOpen={isCagingSettingsModalOpen}
      onRequestClose={handleCagingSettingsModalClose}
      shouldCloseOnOverlayClick
      className={cn('modal')}
      sidebar="right"
      animationType={animationType}
    >
      <Modal.Header onRequestClose={handleCagingSettingsModalClose} className={cn('modal__header')}>
        <h2 className={cn('modal__subtitle')}>{designData.templateName ?? MDASH}</h2>
      </Modal.Header>
      <Modal.Content className={cn('modal__content', 'content')}>
        <div className={cn('content__top-section')}>
          <div>
            <h1 className={cn('content__title')}>CCE settings ranges</h1>
            <span>* Ranges shown but not editable</span>
          </div>
          <div className={cn('content__top-section-divider')} />
          <icons.CageSettingsIcon className={cn('content__section-img')} />
        </div>
        <div className={cn('content__main-content')}>
          <RunDesignTable
            tableData={[]}
            className={cn('table')}
            headerClassName={cn('table__header')}
            header={
              <RunDesignTable.Row>
                <RunDesignTable.Column className={cn('table__header-column')}>Setting name</RunDesignTable.Column>
                <RunDesignTable.Column className={cn('table__header-column')}>VALUE RANGE</RunDesignTable.Column>
                <RunDesignTable.Column />
              </RunDesignTable.Row>
            }
          >
            {settingsList.map(({ key, title, icon, editable, valueType }) => {
              const valueTypeStr = valueType ? `(${valueType})` : '';
              const titleStr = `${title} ${valueTypeStr}`;

              return (
                <RunDesignTable.Row key={key} className={cn('table__row')}>
                  <RunDesignTable.Column
                    className={cn('table__column', { table__column_clicked: selectedSetting === key })}
                  >
                    <div className={cn('table__column-content')}>{!editable ? `${titleStr} *` : titleStr}</div>
                  </RunDesignTable.Column>

                  <RunDesignTable.Column
                    className={cn('table__column', { table__column_clicked: selectedSetting === key })}
                  >
                    <div className={cn('table__column-content', 'table__column-content_bold')}>
                      {getValidationInfo(cageSettingsInputValidationConfig?.[key])}
                    </div>
                  </RunDesignTable.Column>
                  <RunDesignTable.Column className={cn('table__column', 'table__column_icon')}>
                    {icon}
                  </RunDesignTable.Column>
                </RunDesignTable.Row>
              );
            })}
          </RunDesignTable>
        </div>
      </Modal.Content>
      <Modal.Footer className={cn('modal__footer')}>
        <Button
          type="button"
          color="light"
          isOutlined
          onClick={handleCagingSettingsModalClose}
          className={cn('modal__button')}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(CageSettingsInfoModal);
