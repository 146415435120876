import { v4 as uuidv4 } from 'uuid';

import { transformMap } from './helpers';
import { TReturnTransformedTypes } from './types';

export const transformAnnotationResponse = (annotation: TGeneralAnnotationFields): TReturnTransformedTypes =>
  transformMap[annotation.type]?.(annotation) ?? {
    ...annotation,
    uuid: uuidv4(),
  };

export const transformAnnotationsResponse = (annotations: TGeneralAnnotationFields[]): TReturnTransformedTypes[] =>
  annotations.map((annotation) => transformAnnotationResponse(annotation));

export const transformAnnotationsVendorsResponse = (annotations: { vendor: string; id: string }[]): string[] =>
  Array.from(new Set(annotations.map(({ vendor }) => vendor))).slice(0, 8);

export const transformAnnotationsExResponse = (annotations: { excitationWavelength: string; id: string }[]): string[] =>
  Array.from(new Set(annotations.map(({ excitationWavelength }) => excitationWavelength[0]))).slice(0, 8);

export const transformAnnotationsEmResponse = (annotations: { detectionWavelength: string; id: string }[]): string[] =>
  Array.from(new Set(annotations.map(({ detectionWavelength }) => detectionWavelength[0]))).slice(0, 8);
