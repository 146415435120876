import { FC } from 'react';
import classnames from 'classnames/bind';

import { getDefaultWavesConfig } from '@/helpers/waveLengths';
import { OpticalDisplay } from '@/graphql/API';

import WaveChannel from '@/components/common/WaveChannel';
import styles from './Consumable.module.scss';

const cn = classnames.bind(styles);

type TConsumableWaves = {
  className?: string;
  opticalDisplay?: OpticalDisplay | null;
};

const ConsumableWaves: FC<TConsumableWaves> = ({ className, opticalDisplay }) => {
  const defaultWaves = getDefaultWavesConfig();

  return (
    <WaveChannel.Group className={cn('waves', className)}>
      <WaveChannel.Item color={opticalDisplay?.excitation?.hex ?? defaultWaves.ex.color}>
        {opticalDisplay?.excitation?.symbol ?? defaultWaves.ex.label}
      </WaveChannel.Item>
      <WaveChannel.Item color={opticalDisplay?.detection?.hex ?? defaultWaves.em.color}>
        {opticalDisplay?.detection?.symbol ?? defaultWaves.em.label}
      </WaveChannel.Item>
    </WaveChannel.Group>
  );
};

export default ConsumableWaves;
