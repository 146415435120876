import { ChangeEventHandler, FC, FocusEventHandler, KeyboardEventHandler, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import TextInput from '@/components/common/TextInput';
import { Popover as TinyPopover } from 'react-tiny-popover';
import { isDefined } from '@/helpers/typeGuards';
import { useDebounce } from '@/hooks';
import styles from './InputWithSuggestions.module.scss';

const cn = classNames.bind(styles);

type TInputWithSuggestionsProps = {
  className?: string;
  placeholder?: string;
  value?: string;
  suggestionData?: any[];
  isFetching?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
};

const InputWithSuggestions: FC<TInputWithSuggestionsProps> = ({
  className,
  placeholder = '',
  value: outerValue,
  suggestionData,
  isFetching = false,
  onChange,
  onSubmit,
  disabled,
}) => {
  const [value, setValue] = useState<string>('');
  const debouncedValue = useDebounce(value, 500);

  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const isSuggestionOpen = useMemo(
    () => isSearchFocused && !!value.length && !!suggestionData?.length && !isFetching,
    [value, suggestionData, isSearchFocused, isFetching]
  );

  // sync when load existing reagent
  useEffect(() => {
    setValue(outerValue ?? '');
  }, [outerValue]);

  useEffect(() => {
    onChange?.(debouncedValue);
  }, [debouncedValue]);

  const handleSearchInputChange: ChangeEventHandler<HTMLInputElement> = (ev) => {
    setValue(ev.target.value);
  };

  const handleSearchInputFocus: FocusEventHandler<HTMLInputElement> = () => {
    setIsSearchFocused(true);
  };

  const handleSearchInputBlur: FocusEventHandler<HTMLInputElement> = () => {
    // delay for onChange on suggestion trigger, without it suggestions hide before event trigger
    setTimeout(() => setIsSearchFocused(false), 200);
  };

  const handleSearchInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (ev) => {
    if (!isDefined(value)) {
      return;
    }

    if (ev.code === 'Enter') {
      onSubmit?.(value);
      setIsSearchFocused(false);
    }
  };

  const handleSuggestionChangeFactory = (newName: string) => () => {
    setValue(newName);

    onSubmit?.(newName);
  };

  return (
    <TinyPopover
      isOpen={isSuggestionOpen}
      containerClassName={cn('suggestion__wrap', className)}
      positions={['bottom']}
      clickOutsideCapture
      align="start"
      padding={20}
      content={
        <span className={cn('suggestion__list')}>
          {suggestionData?.map((name) => (
            <label key={name} className={cn('suggestion__elem')}>
              <input
                type="radio"
                name="search-suggestion"
                value={name}
                className={cn('suggestion__radio')}
                onChange={handleSuggestionChangeFactory(name)}
              />
              {name}
            </label>
          ))}
        </span>
      }
    >
      <TextInput
        className={cn('suggestion')}
        inputClassName={cn('suggestion__input')}
        value={value}
        placeholder={placeholder}
        onFocus={handleSearchInputFocus}
        onBlur={handleSearchInputBlur}
        onChange={handleSearchInputChange}
        onKeyDown={handleSearchInputKeyDown}
        disabled={disabled}
      />
    </TinyPopover>
  );
};

export default InputWithSuggestions;
