import { FC, MutableRefObject, useCallback, useMemo, MouseEvent, Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import RunDesignCard from '@/components/runDesign/RunDesignCard';
import Button from '@/components/common/Button';
import { useCagingSettingsContext } from '@/hooks/runDesign/useCagingSettingsContext';

import EditSettings from '../EditSettings';
import ReviewSettings from '../ReviewSettings';

import styles from './SettingsCard.module.scss';
import { TLaneCagingSettings } from '../EditSettings/types';

const cn = classnames.bind(styles);

type TSettingsCard = {
  innerRef?: MutableRefObject<Nullable<HTMLDivElement>>;
  newLaneCagingSettings: Nullable<TLaneCagingSettings>[];
  setNewLaneCagingSettings: Dispatch<SetStateAction<Nullable<TLaneCagingSettings>[]>>;
};

const SettingsCard: FC<TSettingsCard> = ({ innerRef, newLaneCagingSettings, setNewLaneCagingSettings }) => {
  const appDispatch = useAppDispatch();
  const isEditMode = useSelector(experimentRunDesignSelectors.selectSomeRunDesignCardIsEdit);
  const runDesignCardIndexExpand = useSelector(experimentRunDesignSelectors.selectRunDesignCardIndexExpand);

  const { isViewAdvancedActive, handleViewAdvancedClick } = useCagingSettingsContext();

  const handleSettingClick = useCallback(() => {
    appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(isEditMode ? -1 : 0));
  }, [isEditMode]);

  const settingsClickHandler = useMemo(
    () => (!isEditMode ? handleSettingClick : undefined),
    [handleSettingClick, isEditMode]
  );

  const handleExpandMode = useCallback(
    (index: number) => {
      const newIndex = runDesignCardIndexExpand === index ? -1 : index;
      appDispatch(experimentRunDesignActions.setRunDesignCardIndexExpand(newIndex));
    },
    [runDesignCardIndexExpand]
  );

  const changeExpandMode = useCallback(() => {
    handleExpandMode?.(0);
  }, [handleExpandMode]);

  const expandModeClickHandler = useMemo(
    () => (isEditMode ? changeExpandMode : undefined),
    [changeExpandMode, isEditMode]
  );

  const handleCardContentClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (isEditMode) return;
      handleSettingClick();
    },
    [isEditMode, handleSettingClick]
  );

  return (
    <RunDesignCard cardRef={innerRef} className={cn('settings-card')}>
      <RunDesignCard.Header
        onSettingClick={settingsClickHandler}
        onExpandClick={expandModeClickHandler}
        className={cn('header')}
        contentClassName={cn('header__content')}
        actionsClassName={cn('header__actions')}
        isEditMode={isEditMode}
        hasDetails
      >
        <div className={cn('header-sections')}>
          <div className={cn('header-sections__container')}>
            <span className={cn('header-sections__title')}>CCE parameters</span>
          </div>
          {isEditMode && (
            <div className={cn('header-sections__container')}>
              <div className={cn('header-sections__btn-container')}>
                <Button
                  onClick={handleViewAdvancedClick}
                  isFitContent
                  isBgLight
                  type="button"
                  color="dark"
                  className={cn('header-sections__view-more')}
                >
                  {isViewAdvancedActive ? 'Hide advanced' : 'View advanced'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </RunDesignCard.Header>
      <RunDesignCard.Content className={cn('content', 'content_no-overflow-scroll')} onClick={handleCardContentClick}>
        {!isEditMode && <ReviewSettings cardRef={innerRef} />}
        {isEditMode && (
          <EditSettings
            cardRef={innerRef}
            isViewMoreActive={isViewAdvancedActive}
            newLaneCagingSettings={newLaneCagingSettings}
            setNewLaneCagingSettings={setNewLaneCagingSettings}
          />
        )}
      </RunDesignCard.Content>
    </RunDesignCard>
  );
};

export default SettingsCard;
