import { TAnnotationsEndpointBuilder } from '@/store/services/app/types';
import {
  transformAnnotationResponse,
  transformAnnotationsEmResponse,
  transformAnnotationsExResponse,
  transformAnnotationsResponse,
  transformAnnotationsVendorsResponse,
} from '../dataProvider/annotation';
import { TAnnotationType } from './types';
import { TReturnTransformedTypes, TWaveLength } from '../dataProvider/types';

export const annotationEndpoints = (build: TAnnotationsEndpointBuilder) => ({
  fetchAnnotationsVendors: build.query<string[], { type: TAnnotationType | TAnnotationType[]; search: string }>({
    query: ({ type, search }) => ({
      url: '/annotations',
      method: 'GET',
      params: {
        type,
        name: search,
        limit: 100,
        projection: ['vendor'],
      },
    }),
    transformResponse: transformAnnotationsVendorsResponse,
  }),

  fetchAnnotationsEx: build.query<string[], { type: TAnnotationType | TAnnotationType[]; search: string }>({
    query: ({ type, search }) => ({
      url: '/annotations',
      method: 'GET',
      params: {
        type,
        name: search,
        limit: 100,
        projection: ['excitationWavelength'],
      },
    }),
    transformResponse: transformAnnotationsExResponse,
  }),

  fetchAnnotationsEm: build.query<string[], { type: TAnnotationType | TAnnotationType[]; search: string }>({
    query: ({ type, search }) => ({
      url: '/annotations',
      method: 'GET',
      params: {
        type,
        name: search,
        limit: 100,
        projection: ['detectionWavelength'],
      },
    }),
    transformResponse: transformAnnotationsEmResponse,
  }),

  fetchAnnotations: build.query<
    TReturnTransformedTypes[],
    { type: TAnnotationType | TAnnotationType[]; search: string; filterList?: Record<string, string> }
  >({
    query: ({ type, search, filterList = {} }) => {
      const filters: Record<string, any> = {};
      if (filterList.vendor) {
        filters.vendor = [filterList.vendor];
      }
      if (filterList.catalogIndex) {
        filters.catalogNumbers = [filterList.catalogIndex];
      }
      if (filterList.ex) {
        filters.excitationWavelengthRange = filterList.ex.split(', ');
      }
      if (filterList.em) {
        filters.detectionWavelengthRange = filterList.em.split(', ');
      }

      Object.entries(filterList).forEach(([key, value]) => {
        if (
          ['disease', 'tissue', 'gender', 'growthProperties', 'reporter', 'geneModification'].includes(key) &&
          !!value
        ) {
          filters[key] = value;
        }
      });

      return {
        url: '/annotations',
        method: 'GET',
        params: {
          ...filters,
          type,
          name: search,
          limit: 100,
        },
      };
    },
    transformResponse: transformAnnotationsResponse,
  }),

  fetchBeadById: build.query<TReturnTransformedTypes, string>({
    query: (id = '') => ({
      url: `/annotations/${id}`,
    }),
    transformResponse: transformAnnotationResponse,
  }),

  fetchWaveLengths: build.query<TWaveLength[], any>({
    query: () => ({
      url: `/wavelengths`,
    }),
  }),

  postAnnotations: build.mutation<TReturnTransformedTypes, Record<string, unknown>>({
    query: (customAnnotationData = {}) => ({
      url: '/annotations',
      method: 'POST',
      body: customAnnotationData,
    }),
    transformResponse: transformAnnotationResponse,
  }),

  patchAnnotations: build.mutation<TReturnTransformedTypes, { id: string; data: Record<string, unknown> }>({
    query: ({ id, data }) => ({
      url: `/annotations/${id}`,
      method: 'PATCH',
      body: data,
    }),
    transformResponse: transformAnnotationResponse,
  }),

  deleteAnnotations: build.mutation<void, string>({
    query: (id) => ({
      url: `/annotations/${id}`,
      method: 'DELETE',
    }),
  }),
});
