import {
  AdvancedOpticsSettings,
  CagingSettingsItem,
  CCEType,
  CellFunction,
  ConsumableToUse,
  Lane,
  LaneCagingSettings,
  LaneConsumablesWithMedia,
  OpticsSettings,
  OpticsSettingsItem,
  Placement,
} from '@/graphql/API';

import {
  arrToMapByKeys,
  DEFAULT_SAMPLE_PREFIX,
  isKeyOf,
  isNumber,
  LANE_LETTER_NAME_LIST,
  removeDuplicates,
} from '@/helpers';
import { isDefined } from '@/helpers/typeGuards';

import {
  ECreatableComponentType,
  TCreatableComponentType,
  TExperimentRunDesignState,
  TMatrixSettingItem,
  TRunDesignCellType,
  TRunDesignComponent,
  TRunDesignConsumable,
  TRunDesignEditFields,
  TRunDesignLane,
  TRunDesignLaneCagingSettings,
  TRunDesignOpticsSettingsItem,
} from '@/store/slices/experimentRunDesign/types';
import { DEFAULT_GLOBAL_SETTINGS, DEFAULT_MAGNIFICATION } from '@/pages/experiment-run-design/CagingSettings/constants';
import getCurrentComponentIndex from '@/pages/experiment-run-design/DesignTimeline/reducer/helpers/getCurrentComponentIndex';
import {
  getInitialCellKillingComponent,
  getInitialCytokineSecretionComponent,
  getInitialIncubationComponent,
  getInitialMRNAComponent,
  getInitialScanComponent,
  getInitialSurfaceReceptorComponent,
} from '@/pages/experiment-run-design/DesignTimeline/reducer/helpers/getInitialComponent';
import { assayIconList } from '@/helpers/runDesigns/constants';
import { getScanOrdersMap } from '@/helpers/runDesigns/timeline';
import {
  isIncubationComponent,
  isOpticalSettings,
  isReagentConsumable,
  isStainConsumable,
} from '@/helpers/runDesigns/typeGuards';
import {
  DEFAULT_EXPOSURE,
  DEFAULT_INTENSITY,
  DEFAULT_ZOFFSET,
} from '@/pages/experiment-run-design/OpticalSettings/constants';
import { MEDIA_OPTIONS } from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsCard/constants';
import { getDefaultWavesConfig } from '@/helpers/waveLengths';

export const getAvailableId = (laneList: TRunDesignLane[], id?: string) => {
  if (id && LANE_LETTER_NAME_LIST.includes(id) && !laneList.find((lane) => lane.id === id)) {
    return id;
  }
  return LANE_LETTER_NAME_LIST.find((laneId) => !laneList.find((lane) => lane.id === laneId));
};

export const removeFromLanes = (lanes: TRunDesignLane[], laneId: string) => {
  const laneIndex = lanes.findIndex((lane) => lane.id === laneId);
  if (laneIndex >= 0) {
    lanes.splice(laneIndex, 1);
  }
};

export const removeLaneFromComponents = (components: TRunDesignComponent[], laneId: string) => {
  components.forEach((component) => {
    if (!Array.isArray(component?.performedOnLanes)) return;

    const laneIndex = component.performedOnLanes.findIndex((laneReagents) => laneReagents.laneId === laneId);

    if (laneIndex >= 0) {
      component.performedOnLanes.splice(laneIndex, 1);
    }
  });
};

// https://cellanome.atlassian.net/wiki/spaces/~7120209b519b0eacc44d9bb6fe3afb63f5becc/pages/644481058/Caging+Specifications
export const defineCellsConfigWithTwoCellTypes = (cellTypes: (TRunDesignCellType | null)[]) => {
  const preLabeledCellTypes = cellTypes.filter((cellType) => isDefined(cellType?.preLabeling?.consumable));

  const preLabeledCellTypesCount = preLabeledCellTypes.length;

  let cellToSubtract: Nullable<TRunDesignCellType> = null;
  let cellToCage: Nullable<TRunDesignCellType> = null;
  let cceType: CCEType = CCEType.ALL_SINGLE_CELLS;

  if (preLabeledCellTypesCount < 2) {
    cellToSubtract = preLabeledCellTypes?.[0] ?? null;
    cellToCage =
      preLabeledCellTypes?.[1] ??
      cellTypes.find((cellType) => cellToSubtract?.cellIndex?.id !== cellType?.cellIndex?.id) ??
      null;
  } else {
    cellToSubtract = preLabeledCellTypes.find((cellType) => cellType?.function === CellFunction.TARGET) ?? null;
    cellToCage = preLabeledCellTypes.find((cellType) => cellType?.function === CellFunction.EFFECTOR) ?? null;
    cceType = CCEType.SUBTRACTIVE_CCE;
  }

  return {
    cellToCage: cellToCage ?? null,
    cellToSubtract,
    cceType,
  };
};

export const defineCellsConfigWithThreeCellTypes = (cellTypes: (TRunDesignCellType | null)[]) => {
  const preLabeledCellTypes: (TRunDesignCellType | null)[] = [];
  const notPreLabeledCellTypes: (TRunDesignCellType | null)[] = [];

  cellTypes.forEach((cellType) => {
    if (isDefined(cellType?.preLabeling?.consumable)) {
      preLabeledCellTypes.push(cellType);
    } else {
      notPreLabeledCellTypes.push(cellType);
    }
  });

  const preLabeledCellTypesCount = preLabeledCellTypes.length;

  let cellToSubtract: Nullable<TRunDesignCellType> = null;
  let cellToCage: Nullable<TRunDesignCellType> = null;
  let cceType: CCEType = CCEType.ALL_SINGLE_CELLS;

  if (preLabeledCellTypesCount < 2) {
    cellToSubtract = preLabeledCellTypes?.[0] ?? null;

    const effectorCellType =
      notPreLabeledCellTypes.find(
        (cellType) => !isDefined(cellType?.preLabeling?.consumable) && cellType?.function === CellFunction.EFFECTOR
      ) ?? null;

    cellToCage = effectorCellType ?? null;
  } else {
    cellToSubtract = preLabeledCellTypes.find((cellType) => cellType?.function === CellFunction.TARGET) ?? null;
    const preLabeledEffectorCellType =
      preLabeledCellTypes.find(
        (cellType) =>
          cellToSubtract?.cellIndex?.id !== cellType?.cellIndex?.id && cellType?.function === CellFunction.EFFECTOR
      ) ?? null;

    cellToCage = preLabeledEffectorCellType ?? null;
    cceType = CCEType.SUBTRACTIVE_CCE;
  }

  if (!cellToCage) {
    notPreLabeledCellTypes.sort((current, next) => {
      const currentName = current?.cellIndex?.name ?? '';
      const nextName = next?.cellIndex?.name ?? '';
      return currentName.localeCompare(nextName);
    });

    [cellToCage] = notPreLabeledCellTypes;
  }

  return {
    cellToCage: cellToCage ?? null,
    cellToSubtract,
    cceType,
  };
};

export const defineLaneCellsConfigs = (lane: TRunDesignLane) => {
  if (!lane?.cellTypes?.length) {
    return {
      cellToCage: null,
      cellToSubtract: null,
      cceType: CCEType.ALL_SINGLE_CELLS,
    };
  }

  if (lane.cellTypes.length === 1) {
    return {
      cellToCage: lane.cellTypes[0],
      cellToSubtract: null,
      cceType: CCEType.ALL_SINGLE_CELLS,
    };
  }

  if (lane.cellTypes.length < 3) {
    return defineCellsConfigWithTwoCellTypes(lane.cellTypes);
  }

  return defineCellsConfigWithThreeCellTypes(lane.cellTypes);
};

export const updateLanesSettings = (state: TExperimentRunDesignState) => {
  if (!state.editFields.current.schema?.cagingSettings?.global || !state.editFields.current.schema.lanes) {
    return;
  }

  const lanesCagingSettingsMap = arrToMapByKeys(
    (state.editFields.current.schema?.cagingSettings.perLane?.filter((lane) =>
      isDefined(lane)
    ) as LaneCagingSettings[]) ?? [],
    'laneId'
  );

  state.editFields.current.schema.cagingSettings.perLane = state.editFields.current.schema.lanes.map((lane) => {
    if (!state.editFields.current.schema?.cagingSettings?.global) {
      return null;
    }

    const existedPerLane = lanesCagingSettingsMap?.[lane.id] ?? {};

    const overridesSettings = {
      ...state.editFields.current.schema.cagingSettings.global,
      ...existedPerLane?.overrideSettings,
    };

    const cellsConfig = defineLaneCellsConfigs(lane);

    const data: TRunDesignLaneCagingSettings = {
      __typename: 'LaneCagingSettings',
      laneId: lane.id,
      overrideSettings: getParsedCagingSettings(overridesSettings),
      magnification: existedPerLane.magnification ?? DEFAULT_MAGNIFICATION,
      ...cellsConfig,
    };

    return data;
  });
};

export const getParsedCagingSettings = (settings: CagingSettingsItem) => {
  const objCopy = structuredClone(settings);
  Object.keys(objCopy).forEach((key) => {
    if (isKeyOf(key, objCopy) && objCopy[key] === null && key !== '__typename') {
      objCopy[key] = DEFAULT_GLOBAL_SETTINGS[key];
    }
  });

  return objCopy;
};

// provide for the case when lanes are provided without cell Types, or when the number of cell Types in lane's differs
export const getParsedLanesData = (lanes?: Nullable<Lane[]>) => {
  const lanesCopy = structuredClone(lanes) ?? [];
  const cellTypesCountInPerLane = lanesCopy.map((lane) => lane.cellTypes?.length ?? 0);
  const maxCellTypesLength = Math.max(...cellTypesCountInPerLane);

  lanesCopy.forEach((lane) => {
    const cellTypes = Array.isArray(lane.cellTypes) ? lane.cellTypes : [null];

    const cellTypesLength = cellTypes.length;
    if (cellTypesLength < maxCellTypesLength) {
      lane.cellTypes = cellTypes.concat(Array(maxCellTypesLength - cellTypesLength).fill(null));
    }
  });
  return lanesCopy;
};

export const updateSampleNames = (state: TExperimentRunDesignState) => {
  if (!state.editFields.current.schema?.lanes?.length) {
    return;
  }

  state.editFields.current.schema.lanes = state.editFields.current.schema.lanes.map((lane) => {
    const sampleName = lane.sample?.trim() ? lane.sample : `${DEFAULT_SAMPLE_PREFIX}-${lane.id}`;
    return {
      ...lane,
      sample: sampleName,
    };
  });
};

export const clearGeneratedSampleNames = (state: TExperimentRunDesignState) => {
  if (!state.editFields.current.schema?.lanes?.length) {
    return;
  }

  state.editFields.current.schema.lanes = state.editFields.current.schema.lanes.map((lane) => {
    const sampleName = lane.sample === `Lane-${lane.id}` ? '' : lane.sample;
    return {
      ...lane,
      sample: sampleName,
    };
  });
};

export const getComponentListWithNewIncubation = (
  components: TRunDesignComponent[],
  id: string,
  placementIndex?: number
) => {
  const firstRowComponentList = components?.filter(({ timing: { placement } }) => placement !== Placement.SIMULTANEOUS);
  const relativeTo = firstRowComponentList?.find((component) => component.id === id)
    ? id
    : firstRowComponentList?.at(-1)?.id;

  const incubationComponents = components?.filter((component) => component.type === 'Incubation');
  const incubationNumbers = incubationComponents?.map((component) => Number(component.name.replace(/\D/g, '') || 1));

  const currentIncubationNumber = incubationComponents?.length > 0 ? Math.max(...incubationNumbers) : 0;

  const incubationComponent = getInitialIncubationComponent(currentIncubationNumber + 1, relativeTo);
  const componentIndex = placementIndex ?? getCurrentComponentIndex(components, id);

  return components?.toSpliced(componentIndex + 1, 0, incubationComponent);
};

export const getComponentListWithNewStep = (
  type: TCreatableComponentType,
  components: TRunDesignComponent[],
  id: string,
  placementIndex?: number
) => {
  const firstRowComponentList = components?.filter(({ timing: { placement } }) => placement !== Placement.SIMULTANEOUS);
  let relativeTo = firstRowComponentList?.find((component) => component.id === id)
    ? id
    : firstRowComponentList?.at(-1)?.id;

  // place CellKilling at start of row by default
  if (type === ECreatableComponentType.CellKilling) {
    relativeTo = firstRowComponentList?.at(0)?.id;
  }

  const sameTypeComponents = components?.filter((component) => component.type === type);
  // rename existing component without counter
  if (sameTypeComponents.length === 1) {
    sameTypeComponents[0].name = `${sameTypeComponents[0].name.trim().replace(/\s\d+$/, '')} 1`;
  }

  const sameTypeComponentNumbers = sameTypeComponents?.map((component) =>
    Number(component.name.replace(/\D+/g, '') || 1)
  );

  const currentSameTypeComponentNumber = sameTypeComponents?.length > 0 ? Math.max(...sameTypeComponentNumbers) : 0;

  const initialComponentList = {
    [ECreatableComponentType.Incubation]: getInitialIncubationComponent,
    [ECreatableComponentType.SurfaceReceptor]: getInitialSurfaceReceptorComponent,
    [ECreatableComponentType.CytokineSecretion]: getInitialCytokineSecretionComponent,
    [ECreatableComponentType.mRNA]: getInitialMRNAComponent,
    [ECreatableComponentType.CellKilling]: getInitialCellKillingComponent,
  };
  const generatedComponent = initialComponentList[type](currentSameTypeComponentNumber + 1, relativeTo);
  const componentIndex = placementIndex ?? getCurrentComponentIndex(components, id);

  const relativeScanComponent = getInitialScanComponent(generatedComponent.id, generatedComponent.id);

  if (generatedComponent.__typename === ECreatableComponentType.mRNA) {
    return components?.toSpliced(componentIndex + 1, 0, generatedComponent);
  }

  if (generatedComponent.scanConfig) {
    generatedComponent.scanConfig.scanIds = [relativeScanComponent.id];
  }
  const resultComponentList = components?.toSpliced(componentIndex + 1, 0, generatedComponent);

  resultComponentList.push(relativeScanComponent);

  return resultComponentList;
};

export const removeIncubation = (components: TRunDesignComponent[]) => {
  // removes last incubation component from the list
  const incubationComponents = components?.filter((component) => component.__typename === 'Incubation') ?? [];
  const lastIncubationComponent = incubationComponents?.[incubationComponents.length - 1] ?? null;

  if (!lastIncubationComponent) return components;

  return components.filter((component) => component.id !== lastIncubationComponent.id);
};

const getExistedOpticalSettings = (
  key: string,
  consumable: TRunDesignConsumable,
  currentAdvancedSettingsMap?: Record<string, AdvancedOpticsSettings>
) => {
  let detectionWavelength = 0;
  let excitationWavelength = 0;

  if (isReagentConsumable(consumable) || isStainConsumable(consumable)) {
    detectionWavelength = consumable?.detectionWavelength ?? 0;
    excitationWavelength = consumable?.excitationWavelength ?? 0;
  }

  return currentAdvancedSettingsMap?.[key]?.settings?.find(
    (setting) =>
      setting.detectionWavelength === detectionWavelength && setting.excitationWavelength === excitationWavelength
  );
};

export const getMatrixOpticalSettings = (componentList: TRunDesignComponent[], editFields?: TRunDesignEditFields) => {
  const resData: TMatrixSettingItem[] = [];
  const scanOrdersMap = getScanOrdersMap(componentList ?? []);
  const currentAdvancedOpticalSettings = editFields?.schema?.opticsSettings?.advanced ?? [];
  const currentAdvancedSettingsMap = arrToMapByKeys(currentAdvancedOpticalSettings, 'laneId', 'scanId');

  // display pre-labeling reagent if first scan exists
  const isFirstScanExists = componentList.some((component) => {
    if ('scanConfig' in component && component.scanConfig) {
      return scanOrdersMap[component.id] === 1;
    }
    return false;
  });

  // TODO: need to investigate for which scan the reagents from pre-treatment should be displayed
  // https://cellanome.atlassian.net/wiki/spaces/~7120209b519b0eacc44d9bb6fe3afb63f5becc/pages/623771651/Optical+Settings

  if (isFirstScanExists) {
    editFields?.schema?.lanes?.forEach((lane) => {
      lane.cellTypes?.forEach((cellType) => {
        if (!cellType?.preLabeling?.consumable) return;

        const { consumable } = cellType.preLabeling;

        const id = `${lane.id}-${consumable.id}`;
        const componentWithPreLabelingScanId = componentList.find((component) => scanOrdersMap?.[component.id] === 1);
        const scanId =
          componentWithPreLabelingScanId &&
          'scanConfig' in componentWithPreLabelingScanId &&
          componentWithPreLabelingScanId.scanConfig
            ? componentWithPreLabelingScanId?.scanConfig?.scanIds?.[0]
            : null;

        const settingKey = `${lane.id}_${scanId}`;

        const currentSettings = getExistedOpticalSettings(
          settingKey,
          cellType.preLabeling.consumable,
          currentAdvancedSettingsMap
        );

        const isConsumableWithWaves = isReagentConsumable(consumable) || isStainConsumable(consumable);
        const opticalDisplay = isConsumableWithWaves ? consumable?.opticalDisplay : null;

        const data: TMatrixSettingItem = {
          lane: {
            __typename: 'LaneReagents',
            laneId: lane.id,
            consumables: (cellType.preLabeling.consumable
              ? [cellType.preLabeling.consumable]
              : []) as unknown as (ConsumableToUse | null)[],
          },
          consumable: cellType.preLabeling.consumable,
          id,
          componentName: 'Pre-labeling',
          componentId: componentWithPreLabelingScanId?.id,
          reagentId: cellType.preLabeling.consumable.id,
          scanConfig: {
            __typename: 'ScanConfig',
            numberOfScans: 1,
            lagTime: 0,
            scanEvery: 0,
            scanIds: scanId ? [scanId] : [],
          },
          exposure: currentSettings?.exposure ?? DEFAULT_EXPOSURE,
          intensity: currentSettings?.intensity ?? DEFAULT_INTENSITY,
          zOffset: currentSettings?.zOffset ?? DEFAULT_ZOFFSET,
          opticalDisplay,
        };
        resData.push(data);
      });
    });
  }

  componentList.forEach((component) => {
    component?.performedOnLanes?.forEach((lane) => {
      lane.consumables?.forEach((usedConsumable) => {
        const consumable = { ...usedConsumable?.consumable } as TRunDesignConsumable;
        if (!usedConsumable) return;

        const scanId = 'scanConfig' in component && component.scanConfig ? component?.scanConfig?.scanIds?.[0] : null;

        if (!scanId) return;

        const settingKey = `${lane.laneId}_${scanId}`;

        const currentSettings = getExistedOpticalSettings(settingKey, consumable, currentAdvancedSettingsMap);
        const isConsumableWithWaves = isReagentConsumable(consumable) || isStainConsumable(consumable);
        const opticalDisplay = isConsumableWithWaves ? consumable?.opticalDisplay : null;

        const id = `${lane.laneId}-${usedConsumable.consumable.id}`;
        const data: TMatrixSettingItem = {
          lane,
          consumable: usedConsumable.consumable,
          scanConfig: null,
          id,
          componentName: component.name,
          componentId: component.id,
          assayIcon: assayIconList[component.type],
          reagentId: usedConsumable.consumable.id,
          exposure: currentSettings?.exposure ?? DEFAULT_EXPOSURE,
          intensity: currentSettings?.intensity ?? DEFAULT_INTENSITY,
          zOffset: currentSettings?.zOffset ?? DEFAULT_ZOFFSET,
          opticalDisplay,
        };

        if ('scanConfig' in component && component.scanConfig) {
          // todo: multiplex by scans
          const newId = `${component.scanConfig.scanIds[0]}-${lane.laneId}-${usedConsumable.consumable.id}`;
          data.id = newId;
          data.scanConfig = {
            ...component.scanConfig,
          };
        }

        resData.push(data);
      });
    });
  });

  return resData;
};

export const updateCurrentOpticSettings = (state: TExperimentRunDesignState, opticSettingsFromAPI: OpticsSettings) => {
  if (!state.editFields?.current?.schema?.opticsSettings || !state.editFields.current.schema?.components?.length) {
    return;
  }

  const matrixOpticalSettings = getMatrixOpticalSettings(
    state.editFields.current.schema.components,
    state.editFields.current
  );
  const defaultExLabel = getDefaultWavesConfig().ex.label;
  const defaultEmLabel = getDefaultWavesConfig().em.label;

  const settingsFromAPIByKeyMap = opticSettingsFromAPI?.global?.reduce<Record<string, OpticsSettingsItem>>(
    (acc, setting) => {
      const key = `${setting.opticalDisplay?.detection?.symbol ?? defaultEmLabel}_${
        setting.opticalDisplay?.excitation?.symbol ?? defaultExLabel
      }`;
      acc[key] = setting;

      return acc;
    },
    {}
  );

  const parsedMatrixSettings = parseLanesMatrixSettings(matrixOpticalSettings);

  state.editFields.current.schema.opticsSettings.advanced = parsedMatrixSettings;

  state.editFields.current.schema.opticsSettings.global = removeDuplicates(matrixOpticalSettings, 'reagentId').map(
    (setting) => {
      const reagentInfo = setting.consumable ?? null;
      const isReagentWithWaves = isReagentConsumable(reagentInfo) || isStainConsumable(reagentInfo);
      const key = isReagentWithWaves
        ? `${reagentInfo?.opticalDisplay?.detection?.symbol ?? defaultEmLabel}_${
            reagentInfo?.opticalDisplay?.excitation?.symbol ?? defaultExLabel
          }`
        : null;

      const savedSetting = key ? settingsFromAPIByKeyMap?.[key] : null;

      const exposure = isNumber(savedSetting?.exposure) ? savedSetting?.exposure : DEFAULT_EXPOSURE;
      const intensity = isNumber(savedSetting?.intensity) ? savedSetting?.intensity : DEFAULT_INTENSITY;
      const zOffset = isNumber(savedSetting?.zOffset) ? savedSetting?.zOffset : DEFAULT_ZOFFSET;

      return {
        __typename: 'OpticsSettingsItem',
        detectionWavelength: isReagentWithWaves ? reagentInfo?.detectionWavelength ?? 0 : 0,
        excitationWavelength: isReagentWithWaves ? reagentInfo?.excitationWavelength ?? 0 : 0,
        exposure,
        intensity,
        zOffset,
        opticalDisplay: savedSetting?.opticalDisplay ?? null,
      };
    }
  );
};

export const parseLanesMatrixSettings = (settings: TMatrixSettingItem[], withResetToDefault?: boolean) => {
  const parsedData = settings.reduce<Record<string, AdvancedOpticsSettings>>((acc, setting) => {
    const scanId = setting?.scanConfig?.scanIds?.[0];
    const laneId = setting?.lane.laneId;

    if (!scanId || !laneId) return acc;
    const key = `${scanId}_${laneId}`;

    let detectionWavelength = 0;
    let excitationWavelength = 0;

    if (isReagentConsumable(setting.consumable) || isStainConsumable(setting.consumable)) {
      detectionWavelength = setting.consumable?.detectionWavelength ?? 0;
      excitationWavelength = setting.consumable?.excitationWavelength ?? 0;
    }

    const opticalSettingItem: OpticsSettingsItem = {
      __typename: 'OpticsSettingsItem',
      detectionWavelength,
      excitationWavelength,
      intensity: withResetToDefault ? DEFAULT_INTENSITY : setting.intensity,
      exposure: withResetToDefault ? DEFAULT_EXPOSURE : setting.exposure,
      zOffset: withResetToDefault ? DEFAULT_ZOFFSET : setting.zOffset,
      opticalDisplay: setting?.opticalDisplay ?? null,
    };

    if (!acc?.[key]) {
      acc[key] = {
        __typename: 'AdvancedOpticsSettings',
        laneId,
        scanId,
        settings: [opticalSettingItem],
      };
    } else if (isKeyOf(key, acc) && isOpticalSettings(acc[key].settings)) {
      const settingLink = acc[key].settings as OpticsSettingsItem[];
      settingLink.push(opticalSettingItem);
    }

    return acc;
  }, {});

  return Object.values(parsedData);
};

export const isSpecificGlobalSettingChanged = (
  prevGlobalSetting: TRunDesignOpticsSettingsItem,
  currentGlobalSetting: TRunDesignOpticsSettingsItem,
  key: string
) => {
  if (!isKeyOf(key, prevGlobalSetting) || !isKeyOf(key, currentGlobalSetting)) return true;

  return currentGlobalSetting[key] !== prevGlobalSetting[key];
};

export const getParsedPerformedOnLanesMediaData = (state: TExperimentRunDesignState) => {
  if (!state.editFields.current?.schema?.components) return;

  state.editFields.current.schema.components = state.editFields.current.schema.components?.map((component) => {
    const isIncubation = isIncubationComponent(component);

    if (!isIncubation) {
      return component;
    }

    const mediaSettingsByKeyMap: Record<string, LaneConsumablesWithMedia> = arrToMapByKeys(
      component?.performedOnLanesWithMedia ?? [],
      'laneId'
    );

    state.editFields.current.schema?.lanes?.forEach((lane) => {
      if (mediaSettingsByKeyMap?.[lane.id]) return;
      mediaSettingsByKeyMap[lane.id] = {
        __typename: 'LaneConsumablesWithMedia',
        media: MEDIA_OPTIONS[0].value,
        laneId: lane.id,
      };
    }, []);

    return {
      ...component,
      performedOnLanesWithMedia: Object.values(mediaSettingsByKeyMap),
    };
  });
};
