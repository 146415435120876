import { Dispatch, FC, SetStateAction } from 'react';
import classnames from 'classnames/bind';
import Button from '@/components/common/Button';
import { antnAPI } from '@/store/services/annotation';
import { TReturnTransformedTypes } from '@/store/services/annotation/dataProvider/types';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';
import { useConfirmationModalContext } from '@/components/common/ConfirmationModalProvider';
import icons from '@/components/common/icons';
import { useReagentModalContext } from '@/pages/experiment-run-design/ReagentsForAssays/context/context';
import styles from './DeleteButton.module.scss';

const cn = classnames.bind(styles);

type TDeleteButtonProps = {
  className?: string;
  info: TReturnTransformedTypes;
  onDelete: (deletedAnnotation: TReturnTransformedTypes) => void;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const DeleteButton: FC<TDeleteButtonProps> = ({ className, info, onDelete, setIsLoading }) => {
  const { config } = useReagentModalContext();
  const confirmationModal = useConfirmationModalContext();
  const [deleteAnnotations] = antnAPI.useDeleteAnnotationsMutation();

  const handleDeleteButtonClick = async () => {
    const isConfirmed = await confirmationModal.onOpen({
      confirmationText: `Are you sure you want to delete the "${info.name}"?`,
      approveButtonText: 'Confirm',
    });

    if (!isConfirmed) {
      return;
    }

    try {
      setIsLoading(true);
      const res = await deleteAnnotations(info.id);
      if ('error' in res) {
        showErrorToast(getErrorMessage(res.error));
        return;
      }

      onDelete(info);
    } catch (error) {
      showErrorToast(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button className={cn('delete-button', className)} color="light" isOutlined onClick={handleDeleteButtonClick}>
      <icons.DeleteIcon className={cn('delete-button__icon')} />
      Delete {config.annotationTypeText}
    </Button>
  );
};

export default DeleteButton;
