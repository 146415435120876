import { CSSProperties, FC, MouseEvent, MouseEventHandler, useMemo, useRef, useState } from 'react';
import classNames from 'classnames/bind';

import { getComponentPosition, getScanLabelsMap } from '@/helpers/runDesigns/timeline';
import { isCellKillingComponent, isScanComponent } from '@/helpers/runDesigns/typeGuards';

import { CellKilling, Placement } from '@/graphql/API';

import {
  ECreatableComponentType,
  TCreatableComponentType,
  TRunDesignComponent,
} from '@/store/slices/experimentRunDesign';

import icons from '@/components/common/icons';

import useOutsideClick from '@/hooks/useOutsideClick';
import SchemaComponent from '../SchemaComponent';

import styles from './ComponentSelector.module.scss';

const cn = classNames.bind(styles);

type TComponentSelectorProps = {
  componentList: TRunDesignComponent[];
  currentComponentId: string;
  onComponentClickFactory: (id: string) => () => void;
  onAddStepClickFactory: (type: TCreatableComponentType) => MouseEventHandler<HTMLButtonElement>;
};

const ComponentSelector: FC<TComponentSelectorProps> = ({
  componentList,
  currentComponentId,
  onComponentClickFactory,
  onAddStepClickFactory,
}) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const addStepRef = useRef<HTMLDivElement>(null);
  const scanLabelsMap = useMemo(() => getScanLabelsMap(componentList ?? []), [componentList]);
  const isCellKillingSelected = useMemo(
    () =>
      !!componentList?.find((component) => isCellKillingComponent(component) && currentComponentId === component.id),
    [currentComponentId, componentList]
  );

  const timelineComponentList = useMemo(
    () => componentList?.filter((component) => !isScanComponent(component)) ?? [],
    [componentList]
  );

  const renderingComponentList = useMemo(() => {
    const firstRowComponentList = timelineComponentList.filter(
      (component) => component.timing.placement !== Placement.SIMULTANEOUS
    );
    let simultaneousRowIndex = 2;

    return timelineComponentList.map((component) => {
      let rowIndex = 1;
      if (component.timing.placement === Placement.SIMULTANEOUS) {
        rowIndex = simultaneousRowIndex;
        simultaneousRowIndex += 1;
      }

      return {
        component,
        position: getComponentPosition(firstRowComponentList, component, rowIndex),
      };
    });
  }, [timelineComponentList]);

  const cellKillingOnTimelineData = useMemo(() => {
    const cellKillingRenderData = renderingComponentList?.find(({ component }) => isCellKillingComponent(component));

    if (!cellKillingRenderData) return null;

    const { component: cellKillingComponent, position: cellKillingPosition } = cellKillingRenderData;

    const relativeToIndex = renderingComponentList.findIndex(
      ({ component }) => component.id === cellKillingComponent?.timing.relativeTo
    );
    return { relativeToIndex, row: cellKillingPosition.row, component: cellKillingComponent as CellKilling };
  }, [currentComponentId, renderingComponentList]);

  const firstRowRunsCount = useMemo(
    () => timelineComponentList.filter((component) => component.timing.placement !== Placement.SIMULTANEOUS).length,
    [timelineComponentList]
  );

  useOutsideClick(addStepRef, () => {
    setIsAddOpen(false);
  });

  const handleAddStepClick = () => {
    setIsAddOpen((prev) => !prev);
  };

  const handleAddStepClickFactory = (type: TCreatableComponentType) => (ev: MouseEvent<HTMLButtonElement>) => {
    onAddStepClickFactory(type)(ev);
    setIsAddOpen(false);
  };

  const stepNameMap = useMemo(
    () => ({
      [ECreatableComponentType.Incubation]: 'Incubation',
      [ECreatableComponentType.SurfaceReceptor]: 'Surface Receptor',
      [ECreatableComponentType.CytokineSecretion]: 'Cytokine Secretion',
      [ECreatableComponentType.CellKilling]: 'Cell Killing',
      [ECreatableComponentType.mRNA]: 'mRNA',
    }),
    []
  );

  return (
    <div className={cn('component-selector')} ref={rootRef}>
      <div
        className={cn('component-selector__table')}
        style={{ '--column-count': Math.max(1, firstRowRunsCount) } as CSSProperties}
      >
        {firstRowRunsCount === 0 && <div />}
        {renderingComponentList.map(({ component, position }, index) => (
          <SchemaComponent
            component={component}
            scanLabel={scanLabelsMap[component.id]}
            key={component.id}
            isCurrent={currentComponentId === component.id}
            onClick={onComponentClickFactory(component.id)}
            position={position}
            isCellKillingSelected={isCellKillingSelected}
            cellKillingOnTimelineData={cellKillingOnTimelineData}
            componentIndex={index}
          />
        ))}
      </div>
      <div className={cn('component-selector__add-step-wrap', { _collapsed: isAddOpen })} ref={addStepRef}>
        <div className={cn('component-selector__add-step-list-wrap')}>
          <button className={cn('component-selector__add-step-trigger')} onClick={handleAddStepClick}>
            <icons.PlusIcon className={cn('component-selector__add-step-trigger-icon')} />
            Add Step
          </button>

          <ul className={cn('component-selector__add-step-list', { 'visually-hidden': !isAddOpen })}>
            {Object.values(ECreatableComponentType).map((type) => (
              <li className={cn('component-selector__add-step-elem')} key={type}>
                <button
                  className={cn('component-selector__add-step')}
                  onClick={handleAddStepClickFactory(type)}
                  tabIndex={isAddOpen ? 0 : -1}
                >
                  Add {stepNameMap[type]}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ComponentSelector;
