import { useLatest } from '@/hooks/useLatest';
import { useEffect, RefObject } from 'react';

function useOutsideClick(elementRef: RefObject<HTMLElement>, handler: () => void, attached = true) {
  const latestHandler = useLatest(handler);

  useEffect(() => {
    if (!attached) return;

    const handleClick = (ev: MouseEvent) => {
      if (!elementRef.current) {
        return;
      }

      if (!elementRef.current.contains(ev.target as HTMLElement)) {
        if (typeof latestHandler.current === 'function') {
          latestHandler.current();
        }
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [elementRef, latestHandler, attached]);
}

export default useOutsideClick;
