import { FC, MutableRefObject, useCallback, useMemo, useState, MouseEvent, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import { CagingSettingsItem, LaneCagingSettings } from '@/graphql/API';
import isEqual from 'lodash.isequal';

import {
  experimentRunDesignActions,
  experimentRunDesignSelectors,
  TCagingSettingNumberField,
  TRunDesignLaneCagingSettings,
} from '@/store/slices/experimentRunDesign';
import { isKeyOf } from '@/helpers';

import RunDesignTable from '@/components/runDesign/RunDesignTable';
import icons from '@/components/common/icons';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { RunDesignContext } from '@/pages/experiment-run-design/context';

import styles from './GlobalSettings.module.scss';
import globalSettingsStyles from '../CageSettingsInfoModal/CageSettingsInfoModal.module.scss';

import { ADVANCED_SETTINGS, DEFAULT_GLOBAL_SETTINGS } from '../../constants';
import NumberField from '../EditSettings/components/EditFields/components/NumberField';
import {
  getValidationInfo,
  prepareValidationConfigForAdvancedSettings,
  validateCagingSettings,
} from '../EditSettings/helpers';

const cn = classnames.bind({ ...globalSettingsStyles, ...styles });

type TGlobalSettings = {
  cardRef?: MutableRefObject<Nullable<HTMLDivElement>>;
  openTimelineSettingsModal: () => void;
};

const GlobalSettings: FC<TGlobalSettings> = ({ cardRef, openTimelineSettingsModal }) => {
  const appDispatch = useAppDispatch();

  const globalCagingSettings = useSelector(experimentRunDesignSelectors.selectGlobalCagingSettings);
  const lanesCagingSettings = useSelector(experimentRunDesignSelectors.selectLanesCagingSettings);
  const isEditMode = useSelector(experimentRunDesignSelectors.selectSomeRunDesignCardIsEdit);
  const { overrideCustomFooterConfig } = useContext(RunDesignContext);

  const [newGlobalSettings, setNewGlobalSettings] = useState<Nullable<CagingSettingsItem>>(null);
  const [newLaneSettings, setNewLaneSettings] = useState<LaneCagingSettings[] | undefined>(lanesCagingSettings);
  const [validationErrors, setValidationErrors] = useState({});
  const [isAllSettingsValid, setIsAllSettingsValid] = useState({});

  const cageSettingsInputValidationConfig = prepareValidationConfigForAdvancedSettings(undefined, globalCagingSettings);

  const isSomeGlobalSettingsChanged = useMemo(() => {
    if (!globalCagingSettings) return false;

    return !isEqual(
      {
        __typename: 'CagingSettingsItem',
        ...DEFAULT_GLOBAL_SETTINGS,
      },
      globalCagingSettings
    );
  }, [globalCagingSettings]);

  const editableSettingsList = useMemo(() => {
    if (!globalCagingSettings) return [];
    return ADVANCED_SETTINGS.filter((setting) => isKeyOf(setting.key, globalCagingSettings) && setting.editable);
  }, [globalCagingSettings, isEditMode]);

  const settingsList = useMemo(() => {
    if (!globalCagingSettings) return [];
    return ADVANCED_SETTINGS.filter((setting) => isKeyOf(setting.key, globalCagingSettings) && !setting.editable);
  }, [globalCagingSettings, isEditMode]);

  const handleChangeNumberField = useCallback(
    (field: TCagingSettingNumberField) => (value: number) => {
      setNewGlobalSettings((prev) => {
        if (!prev) return null;
        const newSettings = structuredClone(prev);

        newSettings[field] = value;
        validateCagingSettings(undefined, newSettings);
        return newSettings;
      });
    },
    []
  );

  const handleSettingClick = useCallback(() => {
    appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(isEditMode ? -1 : 0));
  }, [isEditMode]);

  const handleCardContentClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (isEditMode) return;
      handleSettingClick();
    },
    [isEditMode, handleSettingClick]
  );

  const saveGlobalSettings = useCallback(() => {
    if (!globalCagingSettings || !isAllSettingsValid) return;

    const preparedLanesData: TRunDesignLaneCagingSettings[] =
      newLaneSettings?.map((laneInfo) => {
        const updatedLaneInfo = {
          ...laneInfo,
          overrideSettings: {
            ...globalCagingSettings,
            ...newGlobalSettings,
          },
        };

        return updatedLaneInfo;
      }) ?? [];

    appDispatch(
      experimentRunDesignActions.updateGlobalCagingSettings({
        ...globalCagingSettings,
        ...newGlobalSettings,
      })
    );
    appDispatch(experimentRunDesignActions.updateLanesCagingSettings(preparedLanesData));

    appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(-1));
  }, [globalCagingSettings, newGlobalSettings, isAllSettingsValid, newLaneSettings]);

  const resetLastChanges = useCallback(() => {
    if (!globalCagingSettings) return;

    setNewLaneSettings(lanesCagingSettings);
    setNewGlobalSettings(globalCagingSettings);
    appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(-1));
  }, [globalCagingSettings, lanesCagingSettings]);

  const resetGlobalSettingsToDefault = useCallback(() => {
    const preparedLanesData: LaneCagingSettings[] =
      newLaneSettings?.map((laneInfo: LaneCagingSettings) => {
        const updatedLaneInfo: LaneCagingSettings = {
          ...laneInfo,
          overrideSettings: {
            __typename: 'CagingSettingsItem',
            ...laneInfo?.overrideSettings,
            ...DEFAULT_GLOBAL_SETTINGS,
          },
        };

        return updatedLaneInfo;
      }) ?? [];

    setNewLaneSettings(preparedLanesData);
    setNewGlobalSettings((prev) => {
      if (!prev) return null;

      return {
        ...prev,
        ...DEFAULT_GLOBAL_SETTINGS,
      };
    });
  }, [globalCagingSettings, newGlobalSettings]);

  useEffect(() => {
    if (!overrideCustomFooterConfig) {
      return;
    }

    overrideCustomFooterConfig({
      saveAndContinue: {
        clickHandler: saveGlobalSettings,
        disabled: !isAllSettingsValid,
      },
      cancel: {
        clickHandler: resetLastChanges,
      },
    });
  }, [
    saveGlobalSettings,
    resetLastChanges,
    overrideCustomFooterConfig,
    globalCagingSettings,
    isAllSettingsValid,
    newGlobalSettings,
    isEditMode,
  ]);

  useEffect(() => {
    if (!globalCagingSettings) return;

    setNewGlobalSettings(globalCagingSettings);
  }, [globalCagingSettings]);

  useEffect(() => {
    if (!newGlobalSettings) return;
    const { isValid, errors } = validateCagingSettings(undefined, newGlobalSettings);
    setValidationErrors(errors);
    setIsAllSettingsValid(isValid);
  }, [newGlobalSettings]);

  useEffect(() => {
    setNewLaneSettings(lanesCagingSettings);
  }, [lanesCagingSettings]);

  return (
    <div className={cn('global-settings')} ref={cardRef}>
      <div className={cn('global-settings__header')}>
        <span>Review and edit global CCE settings as needed</span>
        <div className={cn('global-settings__header-buttons')}>
          <button
            type="button"
            onClick={openTimelineSettingsModal}
            className={cn('global-settings__button', 'global-settings__button_timeline')}
          >
            <icons.RunDesignClockIcon className={cn('global-settings__button-icon')} height={30} width={30} />{' '}
          </button>
          {isSomeGlobalSettingsChanged && isEditMode && (
            <button
              type="button"
              onClick={resetGlobalSettingsToDefault}
              className={cn('global-settings__button', 'global-settings__button_reset')}
            >
              <icons.ReloadIcon className={cn('modal__button-icon')} />
              <span className={cn('global-settings__button-substring')}>Reset to default</span>
            </button>
          )}
        </div>
      </div>
      <div
        role="presentation"
        onClick={handleCardContentClick}
        className={cn('container', { container_clickable: !isEditMode })}
      >
        <RunDesignTable
          tableData={[]}
          className={cn('table')}
          headerClassName={cn('table__header')}
          header={
            <RunDesignTable.Row>
              <RunDesignTable.Column>Setting name, VALUE RANGE</RunDesignTable.Column>
              <RunDesignTable.Column>DEFAULT value</RunDesignTable.Column>
              <RunDesignTable.Column />
            </RunDesignTable.Row>
          }
        >
          {editableSettingsList.map(({ key, title, icon, customdata = '' }) => {
            const isMixed =
              newGlobalSettings?.[key] === globalCagingSettings?.[key] &&
              newLaneSettings?.some(({ overrideSettings }) => {
                if (!newGlobalSettings?.[key]) return false;

                return overrideSettings?.[key] !== newGlobalSettings?.[key];
              });

            return (
              <RunDesignTable.Row key={key} className={cn('table__row')}>
                <RunDesignTable.Column className={cn('table__column')}>
                  <div className={cn('table__column-content')}>
                    {title} {customdata}
                  </div>
                  <div className={cn('table__column-content_light')}>
                    {getValidationInfo(cageSettingsInputValidationConfig?.[key])}
                  </div>
                </RunDesignTable.Column>

                <RunDesignTable.Column className={cn('table__column')}>
                  <NumberField
                    key={key}
                    field={key}
                    settings={newGlobalSettings}
                    onChangeNumberField={handleChangeNumberField}
                    defaultValue={DEFAULT_GLOBAL_SETTINGS?.[key] ?? undefined}
                    errors={validationErrors}
                    className={cn('global-settings__input')}
                    valueClassName={cn('global-settings__input-value')}
                    validationConfig={cageSettingsInputValidationConfig}
                    customPopoverContent={
                      isMixed ? (
                        <div className={cn('custom-popover')}>
                          <span>The default CCE setting:</span>
                          <div className={cn('custom-popover__values')}>
                            <span className={cn('custom-popover__key')}> {title}</span>
                            <span>{globalCagingSettings?.[key] ?? undefined}</span>
                          </div>
                          <span>has been manually modified in at least one of the lanes.</span>
                        </div>
                      ) : null
                    }
                    inputContainerClassName={cn({ 'global-settings__input-container': isMixed })}
                    isMixed={isMixed}
                  />
                </RunDesignTable.Column>
                <RunDesignTable.Column className={cn('table__column', 'table__column_icon')}>
                  {icon}
                </RunDesignTable.Column>
              </RunDesignTable.Row>
            );
          })}
        </RunDesignTable>
        <div>
          <RunDesignTable
            tableData={[]}
            className={cn('table')}
            headerClassName={cn('table__header')}
            header={
              <RunDesignTable.Row>
                <RunDesignTable.Column>Setting name, VALUE RANGE</RunDesignTable.Column>
                <RunDesignTable.Column>DEFAULT value</RunDesignTable.Column>
                <RunDesignTable.Column />
              </RunDesignTable.Row>
            }
          >
            {settingsList.map(({ key, title, icon, customdata = '' }, index) => (
              <RunDesignTable.Row key={key} className={cn('table__row')}>
                <RunDesignTable.Column
                  className={cn('table__column', { table__column_bordered: index === settingsList.length - 1 })}
                >
                  <div className={cn('table__column-content')}>
                    {title} {customdata} *
                  </div>
                  <div className={cn('table__column-content_light')}>
                    {getValidationInfo(cageSettingsInputValidationConfig?.[key])}
                  </div>
                </RunDesignTable.Column>
                <RunDesignTable.Column
                  className={cn('table__column', { table__column_bordered: index === settingsList.length - 1 })}
                >
                  <span>{newGlobalSettings?.[key]}</span>
                </RunDesignTable.Column>
                <RunDesignTable.Column
                  className={cn(
                    'table__column',
                    { table__column_bordered: index === settingsList.length - 1 },
                    'table__column_icon'
                  )}
                >
                  {icon}
                </RunDesignTable.Column>
              </RunDesignTable.Row>
            ))}
          </RunDesignTable>
          <div className={cn('info')}>
            <span> *</span>
            <span className={cn('info__text')}>Default values for settings are displayed but cannot be edited.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalSettings;
