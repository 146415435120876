import { FC, Reducer, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash.isequal';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useOpenModal } from '@/hooks/useOpenModal';
import { RunDesignContext } from '@/pages/experiment-run-design/context';

import {
  experimentRunDesignActions,
  experimentRunDesignSelectors,
  TRunDesignComponent,
} from '@/store/slices/experimentRunDesign';

import RunDesignBlockWithLanes from '@/components/runDesign/RunDesignBlockWithLanes';
import { CagingSettingsContextProvider } from '@/hooks/runDesign/useCagingSettingsContext';

import SettingsCard from './components/SettingsCard';
import CustomPageHeader from './components/CustomPageHeader';
import CageSettingsInfoModal from './components/CageSettingsInfoModal';
import GlobalSettings from './components/GlobalSettings';
import SettingsModal from '../DesignTimeline/components/SettingsModal';
import { editReducer } from '../DesignTimeline/reducer';
import { TEditReducerAction } from '../DesignTimeline/reducer/types';
import { TLaneCagingSettings } from './components/EditSettings/types';

const CagingSettings: FC = () => {
  const appDispatch = useAppDispatch();
  const { overrideCustomFooterConfig, clearCustomFooterConfig, addCustomHeaderContent, clearCustomHeaderContent } =
    useContext(RunDesignContext);

  const runDesignCardIndexEdit = useSelector(experimentRunDesignSelectors.selectRunDesignCardIndexEdit);
  const cellCagingComponent = useSelector(experimentRunDesignSelectors.selectComponentByTypeName('CellCaging'));
  const globalCagingSettings = useSelector(experimentRunDesignSelectors.selectGlobalCagingSettings);

  const cardRef = useRef(null);

  const [currentComponentId, setCurrentComponentId] = useState<Nullable<string>>(null);
  const [newLaneCagingSettings, setNewLaneCagingSettings] = useState<Nullable<TLaneCagingSettings>[]>([]);
  const [isGlobalSettingsMode, setIsGlobalSettingsMode] = useState(false);

  const switchGlobalSettingsMode = () => setIsGlobalSettingsMode((prev) => !prev);

  const {
    isOpen: isTimelineSettingsModalOpen,
    setIsOpen: setIsTimelineSettingsModalOpen,
    onClose: onTimelineSettingsModalClose,
  } = useOpenModal();

  const showResetBtn = useMemo(() => {
    if (!newLaneCagingSettings) return false;

    const res = newLaneCagingSettings?.some(
      (laneInfo) => laneInfo && !isEqual(laneInfo.overrideSettings, globalCagingSettings)
    );

    return res;
  }, [newLaneCagingSettings, globalCagingSettings]);

  const resetLaneSettingsToDefault = useCallback(() => {
    if (!globalCagingSettings) return;

    const preparedLanesData: Nullable<TLaneCagingSettings>[] = newLaneCagingSettings?.map((laneInfo) => {
      if (!laneInfo) return null;

      const updatedLaneInfo = {
        __typename: 'LaneCagingSettings',
        ...laneInfo,

        overrideSettings: {
          ...laneInfo.overrideSettings,
          ...globalCagingSettings,
        },
      };

      return updatedLaneInfo;
    });

    setNewLaneCagingSettings(preparedLanesData);
  }, [globalCagingSettings, newLaneCagingSettings]);

  const openTimelineSettingsModal = useCallback(() => {
    if (!cellCagingComponent) return;
    setCurrentComponentId(cellCagingComponent.id);
    setIsTimelineSettingsModalOpen(true);
  }, [cellCagingComponent]);
  const handleChangeCurrentComponentId = (id: string) => setCurrentComponentId(id);

  const [fullComponentsList, dispatchComponentListAction] = useReducer<
    Reducer<Nullable<TRunDesignComponent[]>, TEditReducerAction>
  >(editReducer, null);

  useEffect(() => {
    if (!overrideCustomFooterConfig || !clearCustomFooterConfig) {
      return;
    }

    overrideCustomFooterConfig({
      refCheck: cardRef,
      isEditMode: runDesignCardIndexEdit >= 0,
      cancel: {
        clickHandler: () => {
          appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(-1));
        },
      },
    });
    return () => {
      clearCustomFooterConfig();
    };
  }, [overrideCustomFooterConfig, clearCustomFooterConfig, runDesignCardIndexEdit]);

  useEffect(() => {
    if (!addCustomHeaderContent || !clearCustomHeaderContent) {
      return;
    }

    addCustomHeaderContent(
      <CustomPageHeader
        onClick={switchGlobalSettingsMode}
        isActive={isGlobalSettingsMode}
        isEditMode={runDesignCardIndexEdit >= 0}
        openTimelineSettingsModal={openTimelineSettingsModal}
        isGlobalSettingsMode={isGlobalSettingsMode}
        resetLaneSettingsToDefault={showResetBtn ? resetLaneSettingsToDefault : undefined}
      />
    );

    return () => {
      clearCustomHeaderContent();
    };
  }, [
    addCustomHeaderContent,
    clearCustomHeaderContent,
    runDesignCardIndexEdit,
    isGlobalSettingsMode,
    switchGlobalSettingsMode,
    isGlobalSettingsMode,
    showResetBtn,
    resetLaneSettingsToDefault,
  ]);

  return (
    <CagingSettingsContextProvider>
      <CageSettingsInfoModal />
      <RunDesignBlockWithLanes hideLanesBlock={isGlobalSettingsMode} title="">
        {isGlobalSettingsMode ? (
          <GlobalSettings cardRef={cardRef} openTimelineSettingsModal={openTimelineSettingsModal} />
        ) : (
          <SettingsCard
            innerRef={cardRef}
            newLaneCagingSettings={newLaneCagingSettings}
            setNewLaneCagingSettings={setNewLaneCagingSettings}
          />
        )}
      </RunDesignBlockWithLanes>
      <SettingsModal
        open={isTimelineSettingsModalOpen}
        currentComponentId={currentComponentId}
        setCurrentComponentId={handleChangeCurrentComponentId}
        onClose={onTimelineSettingsModalClose}
        componentList={fullComponentsList}
        dispatchComponentListAction={dispatchComponentListAction}
      />
    </CagingSettingsContextProvider>
  );
};

export default CagingSettings;
