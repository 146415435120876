import {
  createContext,
  useContext,
  ReactNode,
  FC,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import { useOpenModal } from '../useOpenModal';

type TCagingSettingsContext = {
  selectedSetting: Nullable<string>;
  setSelectedSetting: Dispatch<SetStateAction<Nullable<string>>>;
  isCagingSettingsModalOpen: boolean;
  handleCagingSettingsModalOpen: (settingName?: string) => void;
  handleCagingSettingsModalClose: () => void;
  isViewAdvancedActive: boolean;
  handleViewAdvancedClick: () => void;
};

const CagingSettingsContext = createContext<TCagingSettingsContext>({} as TCagingSettingsContext);

export const useCagingSettingsContext = (): TCagingSettingsContext => useContext(CagingSettingsContext);

type TCagingSettingsContextProviderProps = {
  children: ReactNode;
};

export const CagingSettingsContextProvider: FC<TCagingSettingsContextProviderProps> = ({ children }) => {
  const [selectedSetting, setSelectedSetting] = useState<Nullable<string>>(null);
  const [isViewAdvancedActive, setIsViewAdvancedActive] = useState(false);

  const handleViewAdvancedClick = () => setIsViewAdvancedActive((prev) => !prev);

  const {
    isOpen: isCagingSettingsModalOpen,
    setIsOpen: setIsCagingSettingsModalOpen,
    onClose: handleCagingSettingsModalClose,
  } = useOpenModal();

  const handleCagingSettingsModalOpen = useCallback((settingName?: string) => {
    if (settingName) {
      setSelectedSetting(settingName);
    }
    setIsCagingSettingsModalOpen(true);
  }, []);

  const CagingSettingsContextData = useMemo(
    () => ({
      selectedSetting,
      setSelectedSetting,
      isCagingSettingsModalOpen,
      handleCagingSettingsModalOpen,
      handleCagingSettingsModalClose,
      isViewAdvancedActive,
      handleViewAdvancedClick,
    }),
    [
      selectedSetting,
      setSelectedSetting,
      isCagingSettingsModalOpen,
      handleCagingSettingsModalOpen,
      handleCagingSettingsModalClose,
      isViewAdvancedActive,
      handleViewAdvancedClick,
    ]
  );

  return <CagingSettingsContext.Provider value={CagingSettingsContextData}>{children}</CagingSettingsContext.Provider>;
};
