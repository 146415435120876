import { FC, ReactNode, Reducer, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import ReagentsModal from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal';
import {
  configReducer,
  EConfigActionList,
  initialConfig,
  TConfigReducerActionList,
  TConfigReducerState,
} from '@/pages/experiment-run-design/ReagentsForAssays/context/reducer';
import { antnAPI } from '@/store/services/annotation';
import { ReagentModalContext } from './context';

type TReagentModalContextProviderProps = {
  children: ReactNode;
};

const ReagentModalContextProvider: FC<TReagentModalContextProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [fetchWaveLengths, { data: waveLengths }] = antnAPI.useLazyFetchWaveLengthsQuery();

  const [config, dispatchConfigAction] = useReducer<Reducer<TConfigReducerState, TConfigReducerActionList>>(
    configReducer,
    initialConfig
  );

  useEffect(() => {
    fetchWaveLengths(null, true);
  }, []);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const closeModal = useCallback(() => setIsOpen(false), []);

  const reagentModalContextValue = useMemo(
    () => ({
      openModal,
      config,
      waveLengths,
      setConfig: (newConfig: TConfigReducerState) => {
        dispatchConfigAction({ type: EConfigActionList.setConfig, payload: newConfig });
      },
    }),
    [openModal, config, waveLengths]
  );

  return (
    <ReagentModalContext.Provider value={reagentModalContextValue}>
      {children}

      <ReagentsModal isOpen={isOpen} onClose={closeModal} config={config} />
    </ReagentModalContext.Provider>
  );
};

export { useReagentModalContext } from './context';
export default ReagentModalContextProvider;
