import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';

import { DecimalRegex } from '@/helpers/validator';
import { getDefaultWavesConfig } from '@/helpers/waveLengths';
import { isDefined } from '@/helpers/typeGuards';
import { TRunDesignOpticsSettingsItem } from '@/store/slices/experimentRunDesign';

import NumberInput from '@/components/common/NumberInput';
import WaveChannel from '@/components/common/WaveChannel';
import RunDesignTable from '@/components/runDesign/RunDesignTable';
import styles from './GlobalView.module.scss';
import { DEFAULT_EXPOSURE, DEFAULT_INTENSITY, DEFAULT_ZOFFSET } from '../../constants';

const cn = classnames.bind(styles);

type TGlobalSettingRow = {
  settings: TRunDesignOpticsSettingsItem; // TODO: change on OpticsSettingsItem when opticalDisplay will be added in OpticsSettingsItem
  isEditMode: boolean;
  updateSettings: (newOpticalSettings: TRunDesignOpticsSettingsItem) => void;
};

const GlobalSettingRow: FC<TGlobalSettingRow> = ({ settings, isEditMode, updateSettings }) => {
  const [intensity, setIntensity] = useState(settings.intensity ?? DEFAULT_INTENSITY);
  const [exposure, setExposure] = useState(settings.exposure ?? DEFAULT_EXPOSURE);
  const [zOffset, setZOffset] = useState(settings.zOffset ?? DEFAULT_ZOFFSET);

  const handleChangeNumber = useCallback(
    (setState: Dispatch<SetStateAction<number>>) => (num: number) => {
      setState(num);
    },
    []
  );

  const exColor = useMemo(
    () => settings.opticalDisplay?.excitation?.hex ?? getDefaultWavesConfig().ex.color,
    [settings.opticalDisplay?.excitation?.hex]
  );

  const emColor = useMemo(
    () => settings.opticalDisplay?.detection?.hex ?? getDefaultWavesConfig().em.color,
    [settings.opticalDisplay?.detection?.hex]
  );

  const exLabel = useMemo(
    () => settings.opticalDisplay?.excitation?.name ?? getDefaultWavesConfig().ex.uiLabel,
    [settings.opticalDisplay?.excitation?.name]
  );

  const emLabel = useMemo(
    () => settings.opticalDisplay?.detection?.symbol ?? getDefaultWavesConfig().em.label,
    [settings.opticalDisplay?.detection?.symbol]
  );

  useEffect(() => {
    updateSettings({
      ...settings,
      exposure,
      intensity,
      zOffset,
    });
  }, [intensity, exposure, zOffset]);

  useEffect(() => {
    if (isEditMode) return;
    setIntensity(settings.intensity ?? DEFAULT_INTENSITY);
    setExposure(settings.exposure ?? DEFAULT_EXPOSURE);
    setZOffset(settings.zOffset ?? DEFAULT_ZOFFSET);
  }, [isEditMode]);

  return (
    <RunDesignTable.Row>
      <RunDesignTable.Column className={cn('global-view__column')}>
        <WaveChannel.Item color={exColor} className={cn('global-view__wave-channel')}>
          {exLabel}
        </WaveChannel.Item>
      </RunDesignTable.Column>

      <RunDesignTable.Column className={cn('global-view__column')}>
        <WaveChannel.Item color={emColor} className={cn('global-view__wave-channel')}>{emLabel}</WaveChannel.Item>
      </RunDesignTable.Column>
      <RunDesignTable.Column className={cn('global-view__column')}>
        {isEditMode ? (
          <NumberInput
            onChange={handleChangeNumber(setIntensity)}
            className={cn('global-view__input', {
              'global-view__input_changed': isDefined(intensity) && intensity !== DEFAULT_INTENSITY,
            })}
            value={intensity}
            min={0}
            max={100}
            customRegex={DecimalRegex}
          />
        ) : (
          <span
            className={cn('number-field', {
              'number-field_changed': isDefined(intensity) && intensity !== DEFAULT_INTENSITY,
            })}
          >
            {settings.intensity ?? 0}
          </span>
        )}
      </RunDesignTable.Column>
      <RunDesignTable.Column className={cn('global-view__column')}>
        {isEditMode ? (
          <NumberInput
            min={0}
            max={5000}
            onChange={handleChangeNumber(setExposure)}
            className={cn('global-view__input', {
              'global-view__input_changed': isDefined(exposure) && exposure !== DEFAULT_EXPOSURE,
            })}
            value={exposure}
            customRegex={DecimalRegex}
          />
        ) : (
          <span
            className={cn('number-field', {
              'number-field_changed': isDefined(exposure) && exposure !== DEFAULT_EXPOSURE,
            })}
          >
            {settings.exposure ?? 0}
          </span>
        )}
      </RunDesignTable.Column>
      {/* TODO: Get zOffset and save it to settings when ZOffset will be added in OpticsSettingsItem */}
      <RunDesignTable.Column className={cn('global-view__column')}>
        {isEditMode ? (
          <NumberInput
            onChange={handleChangeNumber(setZOffset)}
            className={cn('global-view__input', {
              'global-view__input_changed': isDefined(zOffset) && zOffset !== DEFAULT_ZOFFSET,
            })}
            value={zOffset}
            min={0}
            max={100}
            customRegex={DecimalRegex}
          />
        ) : (
          <span
            className={cn('number-field', {
              'number-field_changed': isDefined(zOffset) && zOffset !== DEFAULT_ZOFFSET,
            })}
          >
            {settings?.zOffset ?? DEFAULT_ZOFFSET}
          </span>
        )}
      </RunDesignTable.Column>
    </RunDesignTable.Row>
  );
};

export default GlobalSettingRow;
