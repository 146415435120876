import { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames/bind';

import { addSelectableAttribute, SELECTABLE_CLASS_NAME } from '@/helpers/reactSelectable';

import { CellIndex, Reagent } from '@/graphql/API';

import Removable from '@/pages/experiment-run-design/SampleInformation/components/EditCellType/components/Removable';
import { useReagentModalContext } from '@/pages/experiment-run-design/ReagentsForAssays/context';
import useCellNameSearch from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/hooks/useCellNameSearch';

import useCellNameFilter from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/FilterBlock/CellnameFilterBlock/useCellNameFilter';
import CellNameFilterBlock from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/FilterBlock/CellnameFilterBlock';
import {
  cellNameSearchResultInformationList,
  cellNameSearchResultsGridLayout,
  CellNameSearchResultsHeading,
  CellNameSearchResultsRow,
} from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/SearchResults/CellNameSearchResults';
import { isDefined } from '@/helpers/typeGuards';

import PreSelectedCellTypeName from '@/components/runDesign/ConsumableComponent/PreSelectedCellTypeName';
import { cellNameCustomFieldList } from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/EditInformation/predefined';
import { EAnnotationType } from '@/store/services/annotation/endpoints/types';
import ReSelectable from '@/pages/experiment-run-design/SampleInformation/components/EditCellType/components/ReSelectable';
import styles from '../../EditCellType.module.scss';

const cn = classNames.bind(styles);

type TEditCellTypeNameProps = {
  cellIndex?: Nullable<CellIndex>;
  otherLaneCellIndexList: CellIndex[];
  laneIndex: number;
  laneId: string;
  onNameChange: (runDesignCardIndex: number, data: Nullable<{ name: string; id: string }>) => void;
  sampleTitle: string;
  isSelectionHidden?: boolean;
};

const EditCellTypeName: FC<TEditCellTypeNameProps> = ({
  cellIndex,
  otherLaneCellIndexList,
  onNameChange,
  laneIndex,
  laneId,
  sampleTitle,
  isSelectionHidden = false,
}) => {
  const cellIndexList = useMemo(() => {
    const res = [...otherLaneCellIndexList];
    if (isDefined(cellIndex) && !res.find(({ id }) => id === cellIndex.id)) {
      res.push(cellIndex);
    }

    return res;
  }, [otherLaneCellIndexList]);

  const handleChange = useCallback(
    (data: Nullable<CellIndex>) => {
      if (!data) {
        onNameChange(laneIndex, null);
        return;
      }
      onNameChange(laneIndex, {
        name: data.name,
        id: data.id,
      });
    },
    [laneIndex, onNameChange]
  );

  const { openModal, setConfig } = useReagentModalContext();
  const { getData } = useCellNameSearch();

  const additionConfig = {
    type: EAnnotationType.cellIndex,
    title: `Lane ${laneId} / ${sampleTitle}`,
    description: `Search for ${sampleTitle}. Use filters to narrow down your search.`,
    search: {
      placeholder: 'Cell line name',
      getData,
      result: {
        headingRenderer: CellNameSearchResultsHeading,
        rowRenderer: CellNameSearchResultsRow,
        cssGridLayout: cellNameSearchResultsGridLayout,
        informationList: cellNameSearchResultInformationList,
      },
    },
    filter: {
      hook: useCellNameFilter,
      renderer: CellNameFilterBlock,
    },
    custom: {
      title: 'Custom cell information',
      fieldList: cellNameCustomFieldList,
    },
    annotationTypeText: 'cell',
    onSelect: (selectedReagent: Nullable<Reagent>) => {
      onNameChange(laneIndex, selectedReagent);
    },
  };

  const editingConfig = {
    ...additionConfig,
    current: cellIndex,
  };

  const handleAddNewClick = () => {
    setConfig(additionConfig);

    openModal();
  };

  const handleEditClick = () => {
    setConfig(editingConfig);

    openModal();
  };

  const removeCellName = () => {
    handleChange(null);
  };

  return (
    <div
      className={cn('cell-content', 'cell-content_name', SELECTABLE_CLASS_NAME)}
      {...addSelectableAttribute({ rowIndex: laneIndex, columnIndex: 0, dataType: 'cellIndex' })}
    >
      {!cellIndex?.name && (
        <PreSelectedCellTypeName
          onClick={handleChange}
          otherLaneCellIndexList={otherLaneCellIndexList}
          onPlusClick={handleAddNewClick}
          isSelectionHidden={isSelectionHidden}
        />
      )}

      {!!cellIndex?.name && (
        <Removable onRemove={removeCellName}>
          <ReSelectable otherOptionsList={cellIndexList} onOptionClick={handleChange}>
            <button className={cn('cell-content__name-block')} onClick={handleEditClick}>
              {cellIndex?.name}
            </button>
          </ReSelectable>
        </Removable>
      )}
    </div>
  );
};

export default EditCellTypeName;
