import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames/bind';
import useLayoutZoom from '@/components/Layout/LayoutEmpty/useLayoutZoom';
import { CSSProperty } from '@/helpers/interfaces';
import styles from './LayoutEmpty.module.scss';
import globalStyles from './LayoutEmptyGlobal.scss';

const cn = classNames.bind({
  ...styles,
  ...globalStyles,
});

const LayoutEmpty: FC = () => {
  const zoom = useLayoutZoom();

  return (
    <div className={cn('layout', 'empty-layout')} id="empty-layout" style={{ '--zoom': zoom } as CSSProperty}>
      <Outlet />
    </div>
  );
};

export default LayoutEmpty;
