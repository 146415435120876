import classNames from 'classnames/bind';
import { FC } from 'react';

import WaveChannel from '@/components/common/WaveChannel';

import styles from './StaticChannel.module.scss';

const cn = classNames.bind(styles);
type TStaticChannelProps = {
  optical?: Nullable<{
    name: string;
    hex: string;
    symbol: string;
  }>;
};

const StaticChannel: FC<TStaticChannelProps> = ({ optical }) => (
  <div className={cn('static-channel')}>
    <WaveChannel.Item className={cn('static-channel__color')} color={optical?.hex}>
      {optical?.symbol}
    </WaveChannel.Item>
    <div className={cn('static-channel__text')}>{optical?.name}</div>
  </div>
);

export default StaticChannel;
