import { FC, MouseEventHandler } from 'react';
import classnames from 'classnames/bind';

import icons from '@/components/common/icons';

import ReSelectable from '@/pages/experiment-run-design/SampleInformation/components/EditCellType/components/ReSelectable';
import { TRunDesignConsumable } from '@/store/slices/experimentRunDesign';
import AddToTableButton from '../AddToTableButton';

import styles from './Consumable.module.scss';

const cn = classnames.bind(styles);

type TPreSelectedConsumable = {
  className?: string;
  onClick: (consumable: TRunDesignConsumable) => void;
  onAddNewClick?: MouseEventHandler<HTMLButtonElement>;
  consumableList: TRunDesignConsumable[];
  text?: string;
  isSelectionHidden?: boolean;
};

const PreSelectedConsumable: FC<TPreSelectedConsumable> = ({
  className,
  onClick,
  onAddNewClick,
  consumableList,
  text,
  isSelectionHidden = false,
}) => (
  <AddToTableButton className={cn('consumable__button', 'preselected-consumable', className)} onClick={onAddNewClick}>
    <div className={cn('consumable', 'consumable_preselected')}>
      <ReSelectable otherOptionsList={consumableList} onOptionClick={onClick} isSelectionHidden={isSelectionHidden}>
        <div className={cn('preselected-consumable__name')}>
          <icons.PlusIcon className={cn('add-button__plus-icon')} />
          <span className={cn('consumable__name')}>{text}</span>
        </div>
      </ReSelectable>
    </div>
  </AddToTableButton>
);

export default PreSelectedConsumable;
