import { CSSProperties, FC, MouseEventHandler, useMemo } from 'react';
import classNames from 'classnames/bind';

import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';
import icons from '@/components/common/icons';
import { CellKilling, Placement } from '@/graphql/API';
import { isNumber } from '@/helpers';
import { isCellKillingComponent, isIncubationComponent } from '@/helpers/runDesigns/typeGuards';

import getAbbreviationName from '@/pages/experiment-run-design/DesignTimeline/helpers/getAbbreviation';
import styles from './SchemaComponent.module.scss';

const cn = classNames.bind(styles);

type TSchemaComponentProps = {
  className?: string;
  scanLabel: string;
  isCurrent: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  position: {
    row: number;
    column: number;
    columnEnd?: number;
  };
  isCellKillingSelected: boolean;
  component: TRunDesignComponent;
  cellKillingOnTimelineData: {
    relativeToIndex: number;
    row: number;
    component: CellKilling;
  } | null;
  componentIndex: number;
};

const SchemaComponent: FC<TSchemaComponentProps> = ({
  className,
  scanLabel,
  isCurrent = false,
  onClick,
  position,
  isCellKillingSelected,
  component,
  cellKillingOnTimelineData,
  componentIndex,
}) => {
  const abbreviationName = useMemo(() => getAbbreviationName(component), [component]);

  const withStain = useMemo(
    () => cellKillingOnTimelineData?.component.deliveryStainsAt?.includes(component.id),
    [component, cellKillingOnTimelineData?.component]
  );

  const showScanTrail = useMemo(
    () =>
      !isCellKillingComponent(component) &&
      component.timing.placement !== Placement.SIMULTANEOUS &&
      isNumber(cellKillingOnTimelineData?.relativeToIndex) &&
      componentIndex >= cellKillingOnTimelineData.relativeToIndex,
    [component, cellKillingOnTimelineData]
  );

  return (
    <button
      className={cn(
        'schema-component',
        {
          'schema-component_current': isCurrent,
          'schema-component_no-scan': !scanLabel,
          'schema-component_cell-killing': isCellKillingComponent(component),
        },
        className
      )}
      onClick={onClick}
      style={
        {
          '--row': position.row,
          '--column': position.column,
          '--column-end': position.columnEnd,
          '--scan-trail-multiplier': cellKillingOnTimelineData ? cellKillingOnTimelineData.row - 1 : 3,
        } as CSSProperties
      }
    >
      <div className={cn('schema-component__wrap')}>
        <div className={cn('schema-component__step', 'schema-component__incubation')}>
          <span className={cn('schema-component__name')}>{component.name}</span>
          <span className={cn('schema-component__name', 'schema-component__name_abbreviation')}>
            {abbreviationName}
          </span>
          {isIncubationComponent(component) && component.hasTreatment && (
            <span>
              <br />
              +TRT
            </span>
          )}
          <div className={cn('schema-component_with-stain')}>
            {withStain && component.timing.placement !== Placement.SIMULTANEOUS && (
              <icons.StainIcon width={10} height={15} />
            )}
          </div>
        </div>
        {scanLabel && (
          <div
            className={cn('schema-component__step', 'schema-component__scan', {
              'schema-component__scan_trail': showScanTrail,
              'schema-component__scan_cell-killing-selected': isCellKillingSelected,
            })}
          >
            {scanLabel}
          </div>
        )}
      </div>
    </button>
  );
};

export default SchemaComponent;
