import { components, OptionProps } from 'react-select';
import classNames from 'classnames/bind';

import { TWaveLength } from '@/store/services/annotation/dataProvider/types';
import WaveChannel from '@/components/common/WaveChannel';

import styles from './ChannelOption.module.scss';

const cn = classNames.bind(styles);

export type TChannelOption = {
  label: string;
  value: string;
  customData: {
    symbol: string;
    hex: string;
    category: TWaveLength['category'];
  };
};

const ChannelOption = (props: OptionProps<TChannelOption>) => {
  const {
    data: {
      label,
      customData: { hex, symbol },
    },
  } = props;

  return (
    <components.Option {...props} className={cn('channel-option')}>
      <WaveChannel.Item color={hex}>{symbol}</WaveChannel.Item>
      <div className={cn('channel-option__text')}>{label}</div>
    </components.Option>
  );
};

export default ChannelOption;
