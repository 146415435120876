import { FC } from 'react';
import { TReturnTransformedTypes, TWaveLength } from '@/store/services/annotation/dataProvider/types';
import FilterInputWithSuggestionFromData from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/FilterBlock/ReagentFilterBlock/FilterInputWithSuggestionFromData';
import ChannelSelector from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/ChannelSelector';
import { isDefined } from '@/helpers/typeGuards';
import FilterInput from '../FilterInput';

type TReagentFilterBlockProps = {
  filterState: Record<string, string>;
  updateFactory: (field: string) => (value: string) => void;
  data?: TReturnTransformedTypes[];
};

const ReagentFilterBlock: FC<TReagentFilterBlockProps> = ({ filterState, updateFactory, data }) => {
  const updateChannel = (field: string) => (waveList: Nullable<TWaveLength[]>) => {
    if (!isDefined(waveList)) {
      return;
    }

    const res = waveList.reduce<string>((acc, wave) => `${acc}${wave.center}/${wave.bandwidth}, `, '').slice(0, -2);

    updateFactory(field)(res);
  };

  return (
    <>
      <FilterInputWithSuggestionFromData
        value={filterState.vendor}
        placeholder="Vendor"
        onChange={updateFactory('vendor')}
        data={data}
        field="vendor"
      />
      <FilterInput value={filterState.catalogIndex} placeholder="Catalog #" onChange={updateFactory('catalogIndex')} />
      <ChannelSelector
        category="excitation"
        shouldCleanTrigger={!filterState.ex.length}
        onChange={updateChannel('ex')}
        isFilterInput
      />
      <ChannelSelector
        category="detection"
        shouldCleanTrigger={!filterState.em.length}
        onChange={updateChannel('em')}
        isFilterInput
      />
    </>
  );
};

export default ReagentFilterBlock;
