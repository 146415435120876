import { FC, memo } from 'react';
import classnames from 'classnames/bind';

import CheckboxInput from '@/components/common/CheckboxInput';

import styles from '../MainBlock.module.scss';

const cn = classnames.bind(styles);

type TIncubationBlockProps = {
  id: string;
  label: string;
  onChange: (step: string, isChecked: boolean) => void;
  isChecked: boolean;
  disabled?: boolean;
};

const StainStepsSwitch: FC<TIncubationBlockProps> = ({ id, label, onChange, isChecked, disabled }) => {
  const onSwitchChange = () => {
    onChange(id, !isChecked);
  };

  return (
    <div key={id} className={cn('main-block__toggle-box')}>
      <CheckboxInput
        checked={isChecked}
        isSwitch
        theme="run-design"
        onChange={onSwitchChange}
        disabled={disabled}
        label={label}
        labelClassName={cn('main-block__checkbox-label')}
      />
    </div>
  );
};

export default memo(StainStepsSwitch);
