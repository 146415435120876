import { FC, useMemo } from 'react';
import classnames from 'classnames/bind';
import Button from '@/components/common/Button';
import DialogModal from '@/components/common/DialogModal';
import styles from './TitleBlock.module.scss';

const cn = classnames.bind(styles);

type TOrderValidatorProps = {
  isOpen?: boolean;
  onClose?: () => void;
  nameList?: string[];
  type?: string;
  relation?: string;
  onConfirm?: (type: string) => void;
  onCancel?: () => void;
};

const OrderValidator: FC<TOrderValidatorProps> = ({
  isOpen = false,
  onClose,
  nameList,
  type,
  relation,
  onConfirm,
  onCancel,
}) => {
  const componentName = useMemo(() => nameList?.[0].replace(/\s\d+$/, '').toLowerCase(), [nameList]);

  const handleCancelClick = () => {
    onCancel?.();
    onClose?.();
  };

  const handleConfirmClick = () => {
    if (!type) {
      onClose?.();
      return;
    }

    onConfirm?.(type);
    onClose?.();
  };

  return (
    <DialogModal title="Renumber?" onClose={handleCancelClick} open={isOpen} className={cn('title-block__dialog')}>
      <div className={cn('title-block__confirmation')}>
        <div className={cn('title-block__confirmation-text-wrap')}>
          <div className={cn('title-block__confirmation-title')}>
            You are inserting <div className={cn('title-block__tag', 'title-block__tag_current')}>{nameList?.[0]}</div>{' '}
            and associated data scan {relation} <div className={cn('title-block__tag')}>{nameList?.[1]}</div>
          </div>
          <span className={cn('title-block__confirmation-text')}>
            We’ll auto-renumber all {componentName} components and scans to maintain the numerical order of the steps
            and scans on the timeline. Shall we proceed?
          </span>
        </div>
        <div className={cn('title-block__confirmation-controls')}>
          <Button
            color="light"
            onClick={handleCancelClick}
            className={cn('title-block__confirmation-button', 'title-block__confirmation-button_cancel')}
          >
            Cancel
          </Button>
          <Button
            color="yellow"
            onClick={handleConfirmClick}
            className={cn('title-block__confirmation-button', 'title-block__confirmation-button_confirm')}
          >
            Yes, renumber
          </Button>
        </div>
      </div>
    </DialogModal>
  );
};

export default OrderValidator;
