import { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames/bind';

import { Reagent } from '@/graphql/API';

import { addSelectableAttribute, SELECTABLE_CLASS_NAME } from '@/helpers/reactSelectable';
import ConsumableComponent from '@/components/runDesign/ConsumableComponent';
import { TRunDesignCellType, TRunDesignConsumable } from '@/store/slices/experimentRunDesign';
import consumableStyles from '@/components/runDesign/ConsumableComponent/Consumable.module.scss';

import { useReagentModalContext } from '@/pages/experiment-run-design/ReagentsForAssays/context';
import useReagentSearch from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/hooks/useReagentSearch';
import useReagentFilter from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/FilterBlock/ReagentFilterBlock/useReagentFilter';
import ReagentFilterBlock from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/FilterBlock/ReagentFilterBlock';
import {
  reagentSearchResultInformationList,
  reagentSearchResultsGridLayout,
  ReagentSearchResultsHeading,
  ReagentSearchResultsRow,
} from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/SearchResults/ReagentSearchResults';
import PreSelectedConsumable from '@/components/runDesign/ConsumableComponent/PreSelectedConsumable';
import { reagentCustomFieldList } from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/EditInformation/predefined';
import { EAnnotationType } from '@/store/services/annotation/endpoints/types';
import { isDefined } from '@/helpers/typeGuards';
import styles from '../../EditCellType.module.scss';

const cn = classNames.bind({ ...styles, ...consumableStyles });

type TEditCellTypePreLabelingProps = {
  reagent?: Nullable<Reagent>;
  cellType?: Nullable<TRunDesignCellType>;
  laneIndex: number;
  laneId: string;
  sampleTitle: string;
  onChangeConsumable: (runDesignCardIndex: number, reagentData: Nullable<TRunDesignConsumable>) => void;
  otherConsumableList: TRunDesignConsumable[];
  isSelectionHidden?: boolean;
};

const EditCellTypePreLabeling: FC<TEditCellTypePreLabelingProps> = ({
  reagent,
  laneIndex,
  onChangeConsumable,
  cellType,
  laneId,
  sampleTitle,
  otherConsumableList,
  isSelectionHidden = false,
}) => {
  const { openModal, setConfig } = useReagentModalContext();
  const { getData } = useReagentSearch();

  const consumableListForExistingConsumable = useMemo(() => {
    const res = [...otherConsumableList];
    if (isDefined(reagent) && !res.find((option) => option.id === reagent.id)) {
      res.push(reagent);
    }

    return res;
  }, [otherConsumableList, reagent]);

  const additionConfig = {
    type: EAnnotationType.reagent,
    title: `Lane ${laneId} / Pre-Labeling`,
    description: `Search for ${sampleTitle} reagent. Use filters to narrow down your search.`,
    search: {
      placeholder: 'Reagent name',
      getData,
      result: {
        headingRenderer: ReagentSearchResultsHeading,
        rowRenderer: ReagentSearchResultsRow,
        cssGridLayout: reagentSearchResultsGridLayout,
        informationList: reagentSearchResultInformationList,
      },
    },
    filter: {
      hook: useReagentFilter,
      renderer: ReagentFilterBlock,
    },
    custom: {
      title: 'Custom reagent information',
      fieldList: reagentCustomFieldList,
    },
    annotationTypeText: 'reagent',
    onSelect: (selectedReagent: Nullable<TRunDesignConsumable>) => {
      onChangeConsumable(laneIndex, selectedReagent);
    },
  };

  const editingConfig = {
    ...additionConfig,
    current: reagent,
  };

  const handleAddNewClick = () => {
    setConfig(additionConfig);

    openModal();
  };

  const handleEditClick = () => {
    setConfig(editingConfig);

    openModal();
  };

  const handleDeleteClick = () => {
    onChangeConsumable(laneIndex, null);
  };

  const handleOptionClick = useCallback(
    (consumable: TRunDesignConsumable) => {
      onChangeConsumable(laneIndex, consumable);
    },
    [laneIndex]
  );

  if (!cellType) {
    return null;
  }

  return (
    <div
      className={cn('cell-content', 'pre-labeling__btn', SELECTABLE_CLASS_NAME)}
      {...addSelectableAttribute({ rowIndex: laneIndex, columnIndex: 3, dataType: 'preLabeling' })}
    >
      {!reagent && (
        <PreSelectedConsumable
          onClick={handleOptionClick}
          onAddNewClick={handleAddNewClick}
          consumableList={otherConsumableList}
          text="Add reagent"
          isSelectionHidden={isSelectionHidden}
        />
      )}

      {!!reagent && (
        <ConsumableComponent
          onClick={handleEditClick}
          consumable={reagent}
          deleteReagent={handleDeleteClick}
          notSameLaneConsumableList={consumableListForExistingConsumable}
          onPreselectedClick={handleOptionClick}
        />
      )}
    </div>
  );
};

export default EditCellTypePreLabeling;
