import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';

import { Compound, Reagent, Stain } from '@/graphql/API';

import {
  experimentRunDesignSelectors,
  TRunDesignConsumable,
  TRunDesignConsumableToUse,
} from '@/store/slices/experimentRunDesign';
import ConsumableComponent from '@/components/runDesign/ConsumableComponent';

import { EDesignStep } from '@/pages/experiment-run-design/types';
import { isReagentConsumable } from '@/helpers/runDesigns/typeGuards';
import { useSelector } from 'react-redux';
import styles from '@/components/runDesign/ConsumableComponent/Consumable.module.scss';
import PreSelectedConsumable from '@/components/runDesign/ConsumableComponent/PreSelectedConsumable';

import { useRunDesignLocation } from '@/pages/experiment-run-design/hooks/useRunDesignLocation';
import { useReagentModalContext } from '@/pages/experiment-run-design/ReagentsForAssays/context';
import useReagentSearch from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/hooks/useReagentSearch';
import useReagentFilter from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/FilterBlock/ReagentFilterBlock/useReagentFilter';
import ReagentFilterBlock from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/FilterBlock/ReagentFilterBlock';
import {
  reagentSearchResultInformationList,
  reagentSearchResultsGridLayout,
  ReagentSearchResultsHeading,
  ReagentSearchResultsRow,
} from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/SearchResults/ReagentSearchResults';
import {
  incubationReagentSearchResultsGridLayout,
  IncubationReagentSearchResultsHeading,
  IncubationReagentSearchResultsRow,
} from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/SearchResults/IncubationReagentSearchResults';
import {
  reagentCustomFieldList,
  treatmentCustomFieldList,
} from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/EditInformation/predefined';
import { EAnnotationType } from '@/store/services/annotation/endpoints/types';
import { isDefined } from '@/helpers/typeGuards';
import { useReagentListContext } from '../context';

const cn = classnames.bind(styles);

export type TReagentCellClick = (
  consumableToUse: Nullable<TRunDesignConsumableToUse>,
  reagentPos: { laneId: string; componentId: string; reagentIndex: number }
) => void;

export type TReagentCell = {
  usedReagent?: Nullable<TRunDesignConsumableToUse>;
  usedReagentIndex: number;
  onPreselectedClick: TReagentCellClick;
  onAddNew: TReagentCellClick;
  laneId: string;
  componentId: string;
  isEdit?: boolean;
  withWaves?: boolean;
};

const EComponentType = {
  select: 'select',
  addNew: 'addNew',
  reagent: 'reagent',
  preselected: 'preselected',
} as const;

type TComponentType = keyof typeof EComponentType;

const ReagentCell: FC<TReagentCell> = ({
  usedReagent,
  usedReagentIndex,
  isEdit,
  laneId,
  componentId,
  onAddNew,
  onPreselectedClick,
  withWaves,
}) => {
  const { openModal, setConfig } = useReagentModalContext();
  const { getData } = useReagentSearch(componentId);

  const { currentStep } = useRunDesignLocation();

  const [componentType, setComponentType] = useState<TComponentType>(EComponentType.addNew);
  const component = useSelector(experimentRunDesignSelectors.selectComponentById(componentId));
  const reagentList = useReagentListContext();

  const notSameLaneReagents = useMemo(() => {
    const sameLane = reagentList?.filter(({ laneLetter }) => laneLetter === laneId)?.[0];
    const otherLanes = reagentList?.filter(({ laneLetter }) => laneLetter !== laneId);
    const sameLaneReagentList =
      (sameLane?.reagents.filter((reagent) => isDefined(reagent)) as TRunDesignConsumableToUse[]) ?? [];

    return (
      otherLanes?.reduce<TRunDesignConsumableToUse[]>((acc, lane) => {
        lane.reagents.forEach((reagent) => {
          if (!reagent) {
            return;
          }

          const currentId = reagent.consumable.id;
          if (acc.find(({ consumable: { id } }) => id === currentId)) {
            return;
          }
          if (sameLaneReagentList?.find(({ consumable: { id } }) => id === currentId)) {
            return;
          }
          acc.push(reagent);
        });

        return acc;
      }, []) ?? []
    );
  }, [reagentList, laneId]);

  const isAnyConsumableExist = useMemo(
    () => reagentList?.some((lane) => lane.reagents.some((reagent) => isDefined(reagent?.consumable))),
    [reagentList]
  );

  const notSameLaneConsumableList = useMemo(
    () => notSameLaneReagents.map(({ consumable }) => consumable),
    [notSameLaneReagents]
  );

  const consumableListForPreSelect = useMemo(() => {
    const res = [...notSameLaneConsumableList];
    if (usedReagent?.consumable && !res.find((consumable) => consumable.id === usedReagent.consumable.id)) {
      res.push(usedReagent.consumable);
    }

    return res;
  }, [notSameLaneConsumableList]);

  const reagentConsumable = useMemo(() => {
    const isValidConsumable = isReagentConsumable(usedReagent?.consumable);
    return (usedReagent?.consumable && isValidConsumable ? usedReagent.consumable : null) as Nullable<
      Reagent | Stain | Compound
    >;
  }, [usedReagent?.consumable]);

  const onSelectChange = useCallback(
    (selectedReagent: Nullable<Reagent | Stain | Compound>) => {
      const newReagent: Nullable<TRunDesignConsumableToUse> = selectedReagent
        ? {
            ...usedReagent,
            consumable:
              selectedReagent ?? ({ id: '', name: '', __typename: 'Reagent', type: 'Reagent' } as TRunDesignConsumable),
            __typename: 'ConsumableToUse',
          }
        : null;
      onAddNew(newReagent, { componentId, laneId, reagentIndex: usedReagentIndex });
    },
    [onAddNew, laneId, componentId, usedReagentIndex]
  );

  const handlePreselectedClick = useCallback(
    (consumable: TRunDesignConsumable) => {
      if (!consumable.id) {
        return;
      }

      if (usedReagent?.consumable.id === consumable.id) {
        return;
      }

      const newReagent = notSameLaneReagents.find((reagent) => reagent.consumable.id === consumable.id);
      if (!newReagent) {
        return;
      }
      onPreselectedClick(newReagent, { laneId, componentId, reagentIndex: usedReagentIndex });
    },
    [usedReagentIndex, laneId, componentId, notSameLaneReagents]
  );

  const reagentStainAdditionConfig = {
    type: component?.__typename === 'CellKilling' ? EAnnotationType.stain : EAnnotationType.reagent,
    title: `Lane ${laneId} / Reagent ${usedReagentIndex + 1}`,
    description: `Search for ${component?.name}${
      component?.name ? ' ' : ''
    }reagent. Use filters to narrow down your search.`,
    search: {
      placeholder: 'Reagent name',
      getData,
      result: {
        headingRenderer: ReagentSearchResultsHeading,
        rowRenderer: ReagentSearchResultsRow,
        cssGridLayout: reagentSearchResultsGridLayout,
        informationList: reagentSearchResultInformationList,
      },
    },
    filter: {
      hook: useReagentFilter,
      renderer: ReagentFilterBlock,
    },
    custom: {
      title: 'Custom reagent informaation',
      fieldList: reagentCustomFieldList,
    },
    annotationTypeText: 'reagent',
    onSelect: (selectedReagent: Nullable<Reagent | Stain>) => {
      onSelectChange(selectedReagent);
      setComponentType(getComponentType());
    },
  };

  const reagentStainEditingConfig = {
    ...reagentStainAdditionConfig,
    current: usedReagent?.consumable ?? null,
  };

  const treatmentAdditionConfig = {
    type: EAnnotationType.compound,
    title: `Lane ${laneId} / Treatment ${usedReagentIndex + 1}`,
    description: `Search for ${component?.name}${
      component?.name ? ' ' : ''
    }treatment. Use filters to narrow down your search.`,
    search: {
      placeholder: 'Reagent name',
      getData,
      result: {
        headingRenderer: IncubationReagentSearchResultsHeading,
        rowRenderer: IncubationReagentSearchResultsRow,
        cssGridLayout: incubationReagentSearchResultsGridLayout,
        informationList: reagentSearchResultInformationList,
      },
    },
    filter: null,
    custom: {
      title: 'Custom treatment information',
      fieldList: treatmentCustomFieldList,
    },
    annotationTypeText: 'treatment',
    onSelect: (selectedReagent: Nullable<Reagent | Stain>) => {
      onSelectChange(selectedReagent);
      setComponentType(getComponentType());
    },
  };

  const treatmentEditingConfig = {
    ...treatmentAdditionConfig,
    current: usedReagent?.consumable ?? null,
  };

  const handleEditClick = useCallback(() => {
    setConfig(currentStep === EDesignStep.incubation ? treatmentEditingConfig : reagentStainEditingConfig);
    openModal();
  }, [onSelectChange, laneId, usedReagentIndex, usedReagent?.consumable, component]);

  const handleAddNewClick = useCallback(() => {
    setConfig(currentStep === EDesignStep.incubation ? treatmentAdditionConfig : reagentStainAdditionConfig);

    openModal();
  }, [openModal, onSelectChange, laneId, usedReagentIndex, usedReagent?.consumable, component]);

  const handleDeleteClick = () => {
    onSelectChange(null);
  };

  const getComponentType = useCallback((): TComponentType => {
    if (usedReagent?.consumable.id) {
      return EComponentType.reagent;
    }

    if (notSameLaneConsumableList.length) {
      return EComponentType.preselected;
    }

    return EComponentType.addNew;
  }, [usedReagent?.consumable.id, notSameLaneConsumableList]);

  useEffect(() => {
    const updatedComponentType = getComponentType();

    setComponentType(updatedComponentType);

    return () => {
      const newUpdatedComponentType = getComponentType();

      setComponentType(newUpdatedComponentType);
    };
  }, [usedReagent?.consumable.id, isEdit, usedReagentIndex]);

  return (
    <div className={cn('consumable-container')}>
      {reagentConsumable && usedReagent?.consumable.id && (
        <ConsumableComponent
          onClick={isEdit ? handleEditClick : null}
          onPreselectedClick={handlePreselectedClick}
          deleteReagent={handleDeleteClick}
          consumable={reagentConsumable}
          withWaves={withWaves}
          notSameLaneConsumableList={consumableListForPreSelect}
        />
      )}
      {componentType === EComponentType.preselected && isEdit && (
        <PreSelectedConsumable
          onClick={handlePreselectedClick}
          onAddNewClick={handleAddNewClick}
          consumableList={notSameLaneConsumableList}
          text={currentStep === EDesignStep.incubation ? 'Add treatment' : 'Add reagent'}
        />
      )}
      {componentType === EComponentType.addNew && isEdit && (
        <PreSelectedConsumable
          onClick={handlePreselectedClick}
          onAddNewClick={handleAddNewClick}
          consumableList={notSameLaneConsumableList}
          text={currentStep === EDesignStep.incubation ? 'Add treatment' : 'Add reagent'}
          isSelectionHidden={!isAnyConsumableExist}
        />
      )}
      {componentType === EComponentType.preselected && !isEdit && <div />}
      {componentType === EComponentType.addNew && !isEdit && <div />}
    </div>
  );
};

export default ReagentCell;
