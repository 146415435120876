import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { TRunDesignConsumable } from '@/store/slices/experimentRunDesign';
import { initialConfig, TConfigReducerState } from '@/pages/experiment-run-design/ReagentsForAssays/context/reducer';
import { TWaveLength } from '@/store/services/annotation/dataProvider/types';

type TReagentModalContext = {
  openModal: () => void;
  setConsumable?: Dispatch<SetStateAction<Nullable<TRunDesignConsumable>>>;
  setComponentId?: Dispatch<SetStateAction<string>>;
  config: TConfigReducerState;
  waveLengths?: TWaveLength[];
  setConfig: (config: TConfigReducerState) => void;
};

export const ReagentModalContext = createContext<TReagentModalContext>({
  openModal: () => null,
  setConfig: () => null,
  waveLengths: [],
  config: initialConfig,
});

export const useReagentModalContext = () => useContext(ReagentModalContext);
