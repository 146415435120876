import { ReactNode } from 'react';

import { ERunDesignSourceType } from '@/types/experimentRunDesign';

import {
  CagingSettings,
  CagingSettingsItem,
  CellCaging,
  CellKilling,
  CellType,
  Component,
  Compound,
  Consumable,
  ConsumableToUse,
  CreateRunDesignFromTemplateInput,
  CytokineSecretion,
  Incubation,
  Lane,
  LaneCagingSettings,
  LaneReagents,
  mRNA,
  OpticalDisplay,
  OpticsSettings,
  OpticsSettingsItem,
  Reagent,
  ScanComp,
  ScanConfig,
  SelectiveRetention,
  Stain,
  SurfaceReceptor,
  SurfaceReceptorCytokineSecretion,
  Treatment,
} from '@/graphql/API';
import { getEnum, includes, TEnumValue } from '@/helpers/enum';

export type TRunDesignComponent =
  | Component
  | SurfaceReceptor
  | SurfaceReceptorCytokineSecretion
  | CellKilling
  | CytokineSecretion
  | mRNA
  | CellCaging
  | ScanComp
  | SelectiveRetention
  | Incubation
  | Treatment;

export type TRunDesignConsumable = Consumable | Reagent | Compound | Stain;

export type TRunDesignConsumableToUse = Omit<ConsumableToUse, 'consumable'> & { consumable: TRunDesignConsumable };

export type TRunDesignCellType = Omit<CellType, 'preTreatment' | 'preLabeling'> & {
  preTreatment?: Nullable<TRunDesignConsumableToUse>;
  preLabeling?: Nullable<TRunDesignConsumableToUse>;
};

export type TRunDesignLaneCagingSettings = Omit<LaneCagingSettings, 'cellToSubtract' | 'cellToCage'> & {
  cellToSubtract?: TRunDesignCellType | null;
  cellToCage?: TRunDesignCellType | null;
};

export type TRunDesignCagingSettings = Omit<CagingSettings, 'perLane'> & {
  perLane: Array<TRunDesignLaneCagingSettings | null>;
};

export type TRunDesignLane = Omit<Lane, 'cellTypes' | 'overrideCagingSettings'> & {
  cellTypes?: Array<TRunDesignCellType | null> | null;
  overrideCagingSettings?: TRunDesignCagingSettings | null;
};

export type TRunDesignEditFields = Pick<
  CreateRunDesignFromTemplateInput,
  'name' | 'description' | 'investigatorId' | 'projectId' | 'externalLinks' | 'organisms'
> & {
  schema?: Nullable<{
    __typename: 'RunDesignSchema';
    components?: TRunDesignComponent[] | null;
    lanes?: TRunDesignLane[] | null;
    cagingSettings?: TRunDesignCagingSettings;
    opticsSettings?: OpticsSettings;
  }>;
};

export type TExperimentRunDesignState = {
  source: {
    type: Nullable<ERunDesignSourceType>;
    id: Nullable<string>;
  };
  data: {
    templateId: Nullable<string>;
    templateName: Nullable<string>;
    runDesignId: Nullable<string>;
    runDesignDate: Nullable<string>;
    wizardStep: Nullable<number>;
  };
  editFields: {
    initial: TRunDesignEditFields;
    current: TRunDesignEditFields;
  };

  addData: {
    runDesignCardIndexEdit: number;
    runDesignCardIndexExpand: number;
    isAdvancedMode: boolean;
    isGlobalSettingsView: boolean;
    isAutoGenerateSampleNames: boolean;
    isSampleNamesGenerated: boolean;
  };
};

export type TMatrixSettingItem = {
  id: string;
  componentName: string;
  componentId?: string;
  assayIcon?: ReactNode;
  scanConfig?: Nullable<ScanConfig>;
  lane: LaneReagents;
  consumable: TRunDesignConsumable;
  reagentId: string;
  intensity: number;
  exposure: number;
  zOffset?: number | null;
  opticalDisplay?: OpticalDisplay | null;
};

export type TCagingSettingNumberField = keyof Omit<CagingSettingsItem, 'targetCells' | '__typename'>;

export type TRunDesignOpticsSettingsItem = OpticsSettingsItem & {
  opticalDisplay?: OpticalDisplay | null;
};

const creatableComponentTypeVariants = [
  'Incubation',
  'SurfaceReceptor',
  'CytokineSecretion',
  'mRNA',
  'CellKilling',
] as const;

export const ECreatableComponentType = getEnum(creatableComponentTypeVariants);

export type TCreatableComponentType = TEnumValue<typeof ECreatableComponentType>;
type TCreatableComponent = Incubation | SurfaceReceptor | CytokineSecretion | mRNA;

export const isIncubationComponent = (component: TRunDesignComponent): component is Incubation =>
  component.__typename === ECreatableComponentType.Incubation;

export const isRemovableComponent = (component: TRunDesignComponent): component is TCreatableComponent =>
  includes(Object.values(ECreatableComponentType), component.type);
