import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';

const getAbbreviationName = (component: TRunDesignComponent) => {
  const { name, type } = component;

  if (type === 'Incubation') {
    return name.replace('Incubation', 'Inc');
  }
  if (type === 'mRNA') {
    return name;
  }

  const namePostfix = name.match(/\d+$/)?.[0] ?? '';
  const nameWithoutPostfix = namePostfix?.length ? name.replace(namePostfix, '') : name;
  const wordsAbbreviation = nameWithoutPostfix.replaceAll(/[a-zA-Z0-9]+/g, (word) => word[0].toUpperCase());
  return `${wordsAbbreviation.replace(' ', '')}${namePostfix?.length ? ` ${namePostfix}` : ''}`;
};

export default getAbbreviationName;
