import { ChangeEventHandler, FC, memo, ReactElement, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { themeOptions } from '@/types/theme';
import Select from '@/components/common/Select';
import { useReagentModalContext } from '@/pages/experiment-run-design/ReagentsForAssays/context/context';

import { TWaveLength } from '@/store/services/annotation/dataProvider/types';
import { isDefined } from '@/helpers/typeGuards';
import SingleValue from './SingleValue';
import FilterSingleValue from './SingleValue/FilterSingleValue';
import ChannelOption, { TChannelOption } from './ChannelOption';
import styles from './ChannelSelector.module.scss';
import FilterPlaceholder from './FilterPlaceholder';

const cn = classNames.bind(styles);

type TChannelSelectorProps = {
  category: TWaveLength['category'];
  name?: string;
  required?: boolean;
  className?: string;
  shouldCleanTrigger?: boolean;
  onChange?: (waveList: Nullable<TWaveLength[]>) => void;
  isFilterInput?: boolean;
};

const ChannelSelector: FC<TChannelSelectorProps> = ({
  className,
  shouldCleanTrigger,
  category,
  name,
  required,
  onChange,
  isFilterInput = false,
}) => {
  const { waveLengths } = useReagentModalContext();
  const [value, setValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const placeholderText = useMemo(() => (category === 'detection' ? 'Select filter' : 'Select channel'), [category]);

  useEffect(() => {
    if (shouldCleanTrigger) {
      setValue('');
      onChange?.(null);
    }
  }, [shouldCleanTrigger, onChange]);

  const customComponents = useMemo(() => {
    let components: Record<string, (props: any) => ReactElement> = {
      Option: ChannelOption,
      SingleValue,
    };
    if (isFilterInput) {
      components = {
        ...components,
        SingleValue: FilterSingleValue,
        Placeholder: FilterPlaceholder,
      };
    }

    return components;
  }, [isFilterInput]);

  const options = useMemo(() => {
    const filteredWaveLengths = waveLengths?.filter((wave) => wave.category === category);
    if (!filteredWaveLengths) {
      return [];
    }

    const singleColorWaves = filteredWaveLengths.filter((wave) => wave.color.name !== 'Multi Band');
    const singleColorOptionList = singleColorWaves.map((wave) => ({
      value: String(wave.center),
      label: wave.color.name,
      customData: {
        symbol: wave.symbol,
        hex: wave.color.hex,
        category,
      },
    }));

    const multiBandWaves = filteredWaveLengths.filter((wave) => wave.color.name === 'Multi Band');
    const multiBandOption = multiBandWaves.reduce<Nullable<TChannelOption>>((acc, wave) => {
      if (!acc) {
        return {
          value: String(wave.center),
          label: wave.color.name,
          customData: {
            symbol: wave.symbol,
            hex: wave.color.hex,
            category,
          },
        };
      }

      acc.value += `, ${wave.center}`;

      return acc;
    }, null);

    const res = singleColorOptionList;
    if (isDefined(multiBandOption)) {
      // todo: remove when multiband will work
      const hardcodedPurelyMultiBandValue = multiBandOption?.value ? multiBandOption.value.slice(-3) : '765';
      multiBandOption.value = hardcodedPurelyMultiBandValue;

      res.push(multiBandOption);
    }

    return res;
  }, [waveLengths, category]);

  const handleOptionChange = (newValue: string) => {
    setValue(newValue);
    setInputValue(newValue);
    onChange?.(waveLengths?.filter(({ center }) => newValue.split(', ').includes(String(center))) ?? null);
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    if (value === target.value) {
      return;
    }

    setInputValue(target.value);

    if (options.find((option) => option.value === target.value)) {
      setValue(target.value);
    } else {
      setValue('');
    }
  };

  // input here - to get data from form
  return (
    <div className={cn('channel-selector', className)}>
      <Select
        value={value}
        placeholder={placeholderText}
        className={cn('channel-selector__select-wrap')}
        controlClassName={cn('channel-selector__select')}
        menuClassName={cn('channel-selector__menu')}
        menuListClassName={cn('channel-selector__list')}
        menuListWrapperClassName={cn('channel-selector__list-wrap')}
        placeholderClassName={cn('channel-selector__placeholder')}
        singleValueClassName={cn('channel-selector__value')}
        valueContainerClassName={cn('channel-selector__value-wrap')}
        theme={themeOptions.light}
        options={options}
        onChange={handleOptionChange}
        customComponents={customComponents}
      />
      <input
        type="text"
        className={cn('visually-hidden')}
        name={name}
        value={inputValue}
        required={required}
        onChange={handleInputChange}
        tabIndex={-1}
        formNoValidate
      />
    </div>
  );
};

export default memo(ChannelSelector);
