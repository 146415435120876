import { FC, useState } from 'react';
import classnames from 'classnames/bind';
import BinIcon from '@/pages/experiment-run-design/DesignTimeline/components/SettingsModal/components/TitleBlock/BinIcon';
import DialogModal from '@/components/common/DialogModal';
import { isDefined } from '@/helpers/typeGuards';
import Button from '@/components/common/Button';
import styles from './TitleBlock.module.scss';

const cn = classnames.bind(styles);

type TRemoverProps = {
  name: string;
  isRemovable: boolean;
  onRemove?: () => void;
};

const Remover: FC<TRemoverProps> = ({ name, isRemovable, onRemove }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRemoveClick = () => {
    setIsModalOpen(true);
  };

  const handleConfirmClick = () => {
    setIsModalOpen(false);

    setTimeout(() => {
      if (isDefined(onRemove)) {
        onRemove();
      }
    }, 300);
  };

  const handleCancelClick = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={cn('title-block__remove-wrap')}>
      <button
        className={cn('title-block__remove', { 'title-block__remove_hidden': !isRemovable })}
        aria-label="delete"
        onClick={handleRemoveClick}
      >
        <BinIcon className={cn('title-block__remove-icon')} />
        Delete Step
      </button>

      <DialogModal
        title="Delete step?"
        onClose={handleCancelClick}
        open={isModalOpen}
        className={cn('title-block__dialog')}
      >
        <div className={cn('title-block__confirmation')}>
          <div className={cn('title-block__confirmation-text-wrap')}>
            <div className={cn('title-block__confirmation-title')}>
              You are removing <div className={cn('title-block__tag', 'title-block__tag_current')}>{name}</div>
            </div>
            <span className={cn('title-block__confirmation-text')}>Are you sure?</span>
          </div>
          <div className={cn('title-block__confirmation-controls')}>
            <Button
              color="light"
              onClick={handleCancelClick}
              className={cn('title-block__confirmation-button', 'title-block__confirmation-button_cancel')}
            >
              Cancel
            </Button>
            <Button
              color="yellow"
              onClick={handleConfirmClick}
              className={cn('title-block__confirmation-button', 'title-block__confirmation-button_confirm')}
            >
              Confirm
            </Button>
          </div>
        </div>
      </DialogModal>
    </div>
  );
};

export default Remover;
