import { ScanConfig } from '@/graphql/API';

const getInitialScanConfig = (): ScanConfig => ({
  __typename: 'ScanConfig',
  numberOfScans: 1,
  lagTime: 0,
  scanEvery: 0,
  scanIds: [],
});

export default getInitialScanConfig;
