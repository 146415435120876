import { SVGProps } from 'react';

const BinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55" fill="none" {...props}>
    <rect
      x="0.75"
      y="-0.75"
      width="53.5"
      height="53.5"
      rx="26.75"
      transform="matrix(1 0 0 -1 0 53.5)"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M33 26V33C33 34.6569 31.6569 36 30 36H24C22.3431 36 21 34.6569 21 33V26"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M30 22V21.385C30 20.5591 30 20.1461 29.8418 19.8295C29.6966 19.539 29.461 19.3034 29.1705 19.1582C28.8539 19 28.4409 19 27.615 19H26.385C25.5591 19 25.1461 19 24.8295 19.1582C24.539 19.3034 24.3034 19.539 24.1582 19.8295C24 20.1461 24 20.5591 24 21.385V22"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path d="M19.5 22.5H34.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M25 27V32" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M29 27V32" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default BinIcon;
