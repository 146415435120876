import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';

const checkIsComponentInValidOrder = (
  component: TRunDesignComponent,
  componentList: TRunDesignComponent[]
): { isValid: true } | { isValid: false; relation: 'before' | 'after'; componentNameList: string[]; type: string } => {
  const sameTypeComponentList = componentList.filter(({ type }) => type === component.type);
  const componentIndexAcrossSameComponentList = sameTypeComponentList.findIndex(({ name }) => name === component.name);
  const prevSameTypeComponent = sameTypeComponentList[componentIndexAcrossSameComponentList - 1] ?? null;
  const nextSameTypeComponent = sameTypeComponentList[componentIndexAcrossSameComponentList + 1] ?? null;
  const orderWithoutSpecification = 1;
  const componentOrder = Number(component.name.match(/\d+$/)?.[0]) ?? orderWithoutSpecification;

  if (prevSameTypeComponent) {
    const prevComponentOrder = Number(prevSameTypeComponent.name.match(/\d+$/)?.[0]) ?? orderWithoutSpecification;
    if (prevComponentOrder && componentOrder && prevComponentOrder > componentOrder) {
      return {
        isValid: false,
        relation: 'after',
        componentNameList: [component.name, prevSameTypeComponent.name],
        type: component.type,
      };
    }
  }

  if (nextSameTypeComponent) {
    const nextComponentOrder = Number(nextSameTypeComponent.name.match(/\d+$/)?.[0]) ?? orderWithoutSpecification;
    if (nextComponentOrder && componentOrder && nextComponentOrder < componentOrder) {
      return {
        isValid: false,
        relation: 'before',
        componentNameList: [component.name, nextSameTypeComponent.name],
        type: component.type,
      };
    }
  }

  return {
    isValid: true,
  };
};

export default checkIsComponentInValidOrder;
