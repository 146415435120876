import { FC, memo } from 'react';
import classNames from 'classnames/bind';

import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';
import icons from '@/components/common/icons';
import { addTooltip } from '@/helpers';
import { ETooltipTypeMap } from '@/helpers/tooltip';

import styles from '../MainBlock.module.scss';
import StainStepsSwitch from './StainStepsSwitch';

const cn = classNames.bind(styles);

type TCellKillingStainContent = {
  switchDataList: Pick<TRunDesignComponent, 'id' | 'name'>[];
  deliveryStainsAt: string[];
  cellKillingStainPlacement: {
    relativeTo: {
      list: TOption[];
      value: Nullable<string>;
      onChange: (value: string, isChecked: boolean) => void;
    };
  };
};

const CellKillingStainContent: FC<TCellKillingStainContent> = ({
  switchDataList,
  cellKillingStainPlacement,
  deliveryStainsAt,
}) => {
  const handleStainStepsSwitchChange = (step: string, isChecked: boolean) => {
    cellKillingStainPlacement.relativeTo.onChange(step, isChecked);
  };

  return (
    <div className={cn('main-block__content')}>
      <div className={cn('main-block__sub-column')}>
        <div className={cn('main-block__block')}>
          <div className={cn('main-block__group')}>
            <div className={cn('main-block__title-group')}>
              <div className={cn('main-block__block-title', 'main-block__block-title_no-gap')}>
                Select steps / washes to deliver cell killing Stain
              </div>
              <div
                className={cn('main-block__info')}
                {...addTooltip('Select steps / washes to deliver cell killing Stain', 'right', ETooltipTypeMap.dialog)}
              >
                <icons.InfoIcon />
              </div>
            </div>
            <div>
              {switchDataList?.map((component) => (
                <StainStepsSwitch
                  key={component.id}
                  id={component.id}
                  label={component.name}
                  isChecked={deliveryStainsAt.includes(component.id)}
                  onChange={handleStainStepsSwitchChange}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CellKillingStainContent);
