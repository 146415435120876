import { components, SingleValueProps } from 'react-select';
import classNames from 'classnames/bind';

import { TChannelOption } from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/ChannelSelector/ChannelOption';
import WaveChannel from '@/components/common/WaveChannel';

import styles from './SingleValue.module.scss';

const cn = classNames.bind(styles);

const SingleValue = (props: SingleValueProps<TChannelOption>) => {
  const {
    data: {
      label,
      customData: { hex, symbol },
    },
  } = props;

  return (
    <components.SingleValue {...props} className={cn('single-value')}>
      <WaveChannel.Item color={hex}>{symbol}</WaveChannel.Item>
      <div className={cn('single-value__text')}>{label}</div>
    </components.SingleValue>
  );
};

export default SingleValue;
