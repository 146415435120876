import { ChangeEventHandler, Dispatch, FC, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import DialogModal from '@/components/common/DialogModal';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import {
  experimentRunDesignActions,
  experimentRunDesignSelectors,
  isRemovableComponent,
  TCreatableComponentType,
  TRunDesignComponent,
} from '@/store/slices/experimentRunDesign';
import { Placement, TemperatureUnit } from '@/graphql/API';
import { isDefined } from '@/helpers/typeGuards';
import {
  isComponentWithIncubation,
  isComponentWithScan,
  isComponentWithWash,
  isScanComponent,
} from '@/helpers/runDesigns/typeGuards';
import { TTimeInputUnit } from '@/components/common/TimeInput';
import {
  TAddStep,
  TEditReducerAction,
  TUpdateTimelinePlacement,
  TUpdateTimelineRelativeTo,
} from '@/pages/experiment-run-design/DesignTimeline/reducer/types';
import { getScanOrdersMap } from '@/helpers/runDesigns/timeline';
import { isNumber } from '@/helpers';
import OrderValidator from '@/pages/experiment-run-design/DesignTimeline/components/SettingsModal/components/TitleBlock/OrderValidator';
import checkIsComponentInValidOrder from '@/pages/experiment-run-design/DesignTimeline/reducer/helpers/isComponentInValidOrder';
import usePrevious from '@/hooks/usePrevious';
import {
  updateTimelinePlacement,
  updateTimelineRelativeTo,
} from '@/pages/experiment-run-design/DesignTimeline/reducer/orderReducer';
import getCurrentComponentIndex from '@/pages/experiment-run-design/DesignTimeline/reducer/helpers/getCurrentComponentIndex';
import { includes } from '@/helpers/enum';
import TitleBlock from './components/TitleBlock';
import MainBlock from './components/MainBlock';
import FooterBlock from './components/FooterBlock';

import ComponentSelector from './components/ComponentSelector';
import styles from './SettingsModal.module.scss';

const cn = classNames.bind(styles);

type TSettingsModalProps = {
  open?: boolean;
  onClose?: () => void;
  currentComponentId: Nullable<string>;
  setCurrentComponentId: (id: string) => void;
  componentList: Nullable<TRunDesignComponent[]>;
  dispatchComponentListAction: Dispatch<TEditReducerAction>;
};

const SettingsModal: FC<TSettingsModalProps> = ({
  open,
  onClose,
  currentComponentId,
  setCurrentComponentId,
  componentList,
  dispatchComponentListAction,
}) => {
  const appDispatch = useAppDispatch();
  const [orderValidatorProps, setOrderValidatorProps] = useState<
    | {
        isOpen: true;
        onClose: () => void;
        onConfirm: (type: string) => void;
        onCancel?: () => void;
        nameList: string[];
        type: string;
        relation: string;
      }
    | {
        isOpen: false;
      }
  >();

  const prevComponentList = usePrevious(componentList);

  const currentEditFields = useSelector(experimentRunDesignSelectors.selectCurrentEditFields);

  const components: TRunDesignComponent[] = useMemo(
    () => currentEditFields.schema?.components ?? [],
    [currentEditFields.schema?.components]
  );

  useEffect(() => {
    dispatchComponentListAction({ type: 'changeComponentList', payload: components });
  }, [components]);

  const currentComponent = useMemo(
    () => componentList?.find(({ id }) => id === currentComponentId) ?? null,
    [componentList, currentComponentId]
  );

  // select if no component selected on modal open
  useEffect(() => {
    if (components?.length) {
      setCurrentComponentId(components[0].id);
    }
  }, [components]);

  const handleRenumberConfirm = (type: string) => {
    dispatchComponentListAction({
      type: 'order/renumber',
      payload: {
        type,
      },
    });
  };

  const handleReorderFinish = (
    newOrderedComponentList: TRunDesignComponent[],
    componentToCheck = currentComponent,
    onConfirm = handleRenumberConfirm,
    onValidPlacement: () => void = () => null
  ) => {
    if (!componentToCheck) {
      return;
    }

    const validationResult = checkIsComponentInValidOrder(componentToCheck, newOrderedComponentList);

    if (validationResult.isValid) {
      setOrderValidatorProps({
        isOpen: false,
      });

      onValidPlacement();

      return;
    }

    setOrderValidatorProps({
      isOpen: true,
      onClose: () => {
        setOrderValidatorProps({
          isOpen: false,
        });
      },
      onConfirm,
      nameList: validationResult.componentNameList,
      type: validationResult.type,
      relation: validationResult.relation,
    });
  };

  // if added stain - select it
  useEffect(() => {
    if (!componentList || !prevComponentList) {
      return;
    }

    let newComponent;

    if (componentList.length > prevComponentList.length) {
      newComponent = componentList.find(
        (component) => !prevComponentList.find((prevComponent) => prevComponent.id === component.id)
      );
    }

    if (componentList.length < prevComponentList.length) {
      const removedComponentList = prevComponentList.filter(
        (prevComponent) => !componentList.find((component) => prevComponent.id === component.id)
      );

      // removed only scans due to multiple scan count change
      if (removedComponentList.every((component) => component.__typename === 'ScanComp')) {
        return;
      }

      const removedComponentIndex = prevComponentList.findIndex(
        (prevComponent) => !componentList.find((component) => prevComponent.id === component.id)
      );

      const indexToSelect = componentList.findLastIndex((component, index) => {
        if (index >= removedComponentIndex) {
          return false;
        }

        return component.__typename !== 'ScanComp';
      });

      newComponent = componentList[Math.max(indexToSelect, 0)];
    }

    if (!newComponent || newComponent.__typename === 'ScanComp') {
      return;
    }

    setCurrentComponentId(newComponent.id);

    requestAnimationFrame(() => handleReorderFinish(componentList, newComponent));
  }, [componentList]);

  // get list of all not-current component's
  const timingRelativeToList = useMemo(
    () =>
      componentList
        ?.filter((component) => component.id !== currentComponentId && !isScanComponent(component))
        ?.filter(({ timing: { placement } }) => placement !== Placement.SIMULTANEOUS)
        .map(({ id, name }) => ({
          value: id,
          label: name,
        })) ?? [],
    [componentList, currentComponentId]
  );

  const handleStepClickFactory = (step: string) => () => {
    setCurrentComponentId(step);
  };

  const handleTimingPlacementChange = (value: Placement) => {
    if (!currentComponent?.timing || !currentComponentId) {
      return;
    }

    if (!componentList) {
      return;
    }

    const placementIndex = getCurrentComponentIndex(componentList, currentComponentId);

    const componentListCopy = updateTimelinePlacement(componentList, placementIndex, value);

    const handleSuccessReorder = () => {
      dispatchComponentListAction({
        type: 'order/updateTimelinePlacement',
        payload: {
          id: currentComponentId,
          value,
          onReorderFinishCb: handleReorderFinish,
        },
      } as TUpdateTimelinePlacement);

      if (includes([Placement.START, Placement.END], value)) {
        return;
      }

      if (currentComponent.timing.relativeTo) {
        dispatchComponentListAction({
          type: 'order/renumber',
          payload: {
            type: currentComponent.type,
          },
        });
        return;
      }

      // get first non-current id
      const relativeTo = componentList?.find(({ id }) => id !== currentComponentId)?.id;

      if (!relativeTo) {
        dispatchComponentListAction({
          type: 'order/renumber',
          payload: {
            type: currentComponent.type,
          },
        });
        return;
      }

      dispatchComponentListAction({
        type: 'order/updateTimelineRelativeTo',
        payload: {
          id: currentComponentId,
          value: relativeTo,
          onReorderFinishCb: handleReorderFinish,
        },
      } as TUpdateTimelineRelativeTo);
    };

    handleReorderFinish(
      componentListCopy,
      currentComponent,
      () => {
        handleSuccessReorder();

        dispatchComponentListAction({
          type: 'order/renumber',
          payload: {
            type: currentComponent.type,
          },
        });
      },
      handleSuccessReorder
    );
  };

  const handleTimingRelativeToChange = (value: string) => {
    if (!currentComponent?.timing || !currentComponentId) {
      return;
    }

    if (!componentList) {
      return;
    }

    const placementIndex = getCurrentComponentIndex(componentList, currentComponentId);

    const componentListCopy = updateTimelineRelativeTo(componentList, placementIndex, value);

    const handleSuccessReorder = () => {
      dispatchComponentListAction({
        type: 'order/updateTimelineRelativeTo',
        payload: {
          id: currentComponentId,
          value,
          onReorderFinishCb: handleReorderFinish,
        },
      });
    };

    handleReorderFinish(
      componentListCopy,
      currentComponent,
      () => {
        handleSuccessReorder();

        dispatchComponentListAction({
          type: 'order/renumber',
          payload: {
            type: currentComponent.type,
          },
        });
      },
      handleSuccessReorder
    );
  };

  const handleCellKillingStainPlacementToChange = (relativeToId: string, isChecked: boolean) => {
    if (!currentComponent?.timing || !currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'order/updateCellKillingStainPlacement',
      payload: {
        id: currentComponentId,
        value: relativeToId,
        isChecked,
      },
    });
  };

  const handlePauseAfterCompletionChange: ChangeEventHandler<HTMLInputElement> = ({ currentTarget: { checked } }) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'updatePauseAfterCompletion',
      payload: {
        id: currentComponentId,
        value: checked,
      },
    });
  };

  const handleIncubationDurationChange = ({ value, unit }: { value: number; unit: TTimeInputUnit }) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'incubation/updateTime',
      payload: {
        id: currentComponentId,
        value,
        unit,
      },
    });
  };

  const handleIncubationTemperatureChange = (value: number) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'incubation/updateTemperature',
      payload: {
        id: currentComponentId,
        value,
      },
    });
  };

  const handleIncubationTemperatureUnitChange = (value: TemperatureUnit) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'incubation/updateTemperatureUnit',
      payload: {
        id: currentComponentId,
        value,
      },
    });
  };

  const handleIncubationLocationOnDeviceChange = (value: boolean) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'incubation/updateLocationOnDevice',
      payload: {
        id: currentComponentId,
        value,
      },
    });
  };

  const handleWashDurationChange = ({ value, unit }: { value: number; unit: TTimeInputUnit }) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'wash/updateTime',
      payload: {
        id: currentComponentId,
        value,
        unit,
      },
    });
  };

  const handleWashTemperatureChange = (value: number) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'wash/updateTemperature',
      payload: {
        id: currentComponentId,
        value,
      },
    });
  };

  const handleWashTemperatureUnitChange = (value: TemperatureUnit) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'wash/updateTemperatureUnit',
      payload: {
        id: currentComponentId,
        value,
      },
    });
  };

  const handleWashNumberOfWashesChange = (value: number) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'wash/updateNumberOfWashes',
      payload: {
        id: currentComponentId,
        value,
      },
    });
  };

  const handleScanNumberOfScansChange = (value: number) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'scan/updateNumberOfScans',
      payload: {
        id: currentComponentId,
        value,
      },
    });
  };

  const handleScanIntervalChange = ({ value, unit }: { value: number; unit: TTimeInputUnit }) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'scan/updateInterval',
      payload: {
        id: currentComponentId,
        value,
        unit,
      },
    });
  };

  const handleScanFirstScanStartChange = ({ value, unit }: { value: number; unit: TTimeInputUnit }) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'scan/updateFirstScanStart',
      payload: {
        id: currentComponentId,
        value,
        unit,
      },
    });
  };

  const handleRemoveClick = () => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'order/removeStep',
      payload: {
        id: currentComponentId,
      },
    });

    if (!currentComponent) {
      return;
    }

    dispatchComponentListAction({
      type: 'order/renumber',
      payload: {
        type: currentComponent.type,
      },
    });
  };

  const handleUpdateIncubationComponentHasTreatment = ({ value }: { value: boolean }) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'media/updateIncubationHasTreatment',
      payload: {
        id: currentComponentId,
        hasTreatment: value,
      },
    });
  };

  const titleBlockProps = {
    name: currentComponent?.name ?? '',
    typename: currentComponent?.__typename,
    afterCompletion: {
      checked: currentComponent?.pauseAfterCompletion ?? false,
      onChange: handlePauseAfterCompletionChange,
    },
    removing: {
      isRemovable: isDefined(currentComponent) && isRemovableComponent(currentComponent),
      onRemove: handleRemoveClick,
    },
    timing: {
      placement: {
        value: currentComponent?.timing.placement ?? null,
        onChange: handleTimingPlacementChange,
      },
      relativeTo: {
        list: timingRelativeToList,
        value: currentComponent?.timing.relativeTo ?? null,
        onChange: handleTimingRelativeToChange,
      },
    },
  };

  const scanOrdersMap = getScanOrdersMap(componentList ?? []);

  const mainBlockProps = {
    component: currentComponent,
    componentList,
    incubationDuration: {
      value: (isComponentWithIncubation(currentComponent) && currentComponent.incubation?.duration) || 0,
      onChange: handleIncubationDurationChange,
    },
    incubationTemperature: {
      value: (isComponentWithIncubation(currentComponent) && currentComponent.incubation?.temperature) || 38,
      onChange: handleIncubationTemperatureChange,
    },
    incubationTemperatureUnit: {
      value:
        (isComponentWithIncubation(currentComponent) && currentComponent.incubation?.temperatureUnit) ||
        TemperatureUnit.C,
      onChange: handleIncubationTemperatureUnitChange,
    },
    incubationLocationOnDevice: {
      value: (isComponentWithIncubation(currentComponent) && currentComponent.incubation?.onDevice) ?? false,
      onChange: handleIncubationLocationOnDeviceChange,
    },
    washDuration: {
      value: (isComponentWithWash(currentComponent) && currentComponent.wash?.duration) || 0,
      onChange: handleWashDurationChange,
    },
    washTemperature: {
      value: (isComponentWithWash(currentComponent) && currentComponent.wash?.temperature) || 0,
      onChange: handleWashTemperatureChange,
    },
    washTemperatureUnit: {
      value: (isComponentWithWash(currentComponent) && currentComponent.wash?.temperatureUnit) || TemperatureUnit.C,
      onChange: handleWashTemperatureUnitChange,
    },
    washNumberOfWashes: {
      value: (isComponentWithWash(currentComponent) && currentComponent.wash?.numberOfWashes) || 0,
      onChange: handleWashNumberOfWashesChange,
    },
    scanOrder: currentComponentId ? scanOrdersMap[currentComponentId] : 0,
    scanNumberOfScans: {
      value:
        isComponentWithScan(currentComponent) && isNumber(currentComponent?.scanConfig?.numberOfScans)
          ? currentComponent?.scanConfig?.numberOfScans
          : 0,
      onChange: handleScanNumberOfScansChange,
    },
    scanInterval: {
      value: (isComponentWithScan(currentComponent) && currentComponent?.scanConfig?.scanEvery) || 0,
      onChange: handleScanIntervalChange,
    },
    scanFirstScanTime: {
      value: (isComponentWithScan(currentComponent) && currentComponent?.scanConfig?.lagTime) || 0,
      onChange: handleScanFirstScanStartChange,
    },
    cellKillingStainPlacement: {
      relativeTo: {
        list: timingRelativeToList,
        value: currentComponent?.timing.relativeTo ?? null,
        onChange: handleCellKillingStainPlacementToChange,
      },
    },
    handleUpdateIncubationComponentHasTreatment,
  };

  const handleAddStep = (type: string) => {
    if (!currentComponentId) {
      return;
    }

    dispatchComponentListAction({
      type: 'order/addStep',
      payload: {
        id: currentComponentId,
        type,
      },
    } as TAddStep);
  };

  const handleAddStepClickFactory = (type: TCreatableComponentType) => () => {
    if (!isDefined(componentList)) {
      return false;
    }

    const currentComponentIndex = componentList.findIndex(({ id }) => id === currentComponentId) ?? -1;
    const sameTypeComponentsPlacedAfterCurrentComponent = componentList.filter(
      (componentData, index) => componentData.type === type && index > currentComponentIndex
    );

    const isWrongPlacement = !!sameTypeComponentsPlacedAfterCurrentComponent.length;

    if (!isWrongPlacement) {
      handleAddStep(type);
      return;
    }

    const lastComponentName = sameTypeComponentsPlacedAfterCurrentComponent?.at(-1)?.name ?? '';
    const lastComponentCounter = Number(lastComponentName.match(/\d+$/) ?? 1);

    const newComponentName = lastComponentName.trim().replace(/\s\d+$/, '');
    const triggeredComponentName =
      sameTypeComponentsPlacedAfterCurrentComponent.length === 1
        ? `${newComponentName} 1`
        : sameTypeComponentsPlacedAfterCurrentComponent[0].name;

    setOrderValidatorProps({
      isOpen: true,
      onClose: () => {
        setOrderValidatorProps({
          isOpen: false,
        });
      },
      onConfirm: () => {
        handleAddStep(type);
        handleRenumberConfirm(type);
      },
      nameList: [`${newComponentName} ${lastComponentCounter + 1}`, triggeredComponentName],
      type,
      relation: 'before',
    });
  };

  const rollbackDataOnClose = () => {
    dispatchComponentListAction({ type: 'changeComponentList', payload: components });
    onClose?.();
  };

  const footerBlockProps = {
    onCancel: rollbackDataOnClose,
    onSave: () => {
      appDispatch(experimentRunDesignActions.updateComponents(componentList as TRunDesignComponent[]));
      onClose?.();
    },
  };

  return (
    <DialogModal open={open} title="Settings" onClose={rollbackDataOnClose}>
      <div className={cn('settings-modal')}>
        {componentList && isDefined(currentComponentId) && (
          <ComponentSelector
            componentList={componentList}
            currentComponentId={currentComponentId}
            onComponentClickFactory={handleStepClickFactory}
            onAddStepClickFactory={handleAddStepClickFactory}
          />
        )}
        {!!currentComponent && (
          <>
            <OrderValidator {...orderValidatorProps} />
            <TitleBlock {...titleBlockProps} />
            <MainBlock {...mainBlockProps} />
            <FooterBlock {...footerBlockProps} />
          </>
        )}
      </div>
    </DialogModal>
  );
};

export default SettingsModal;
