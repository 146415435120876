import { CSSProperties, FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import icons from '@/components/common/icons';
import { Popover as TinyPopover } from 'react-tiny-popover';

import { addTooltip } from '@/helpers';
import { isDefined } from '@/helpers/typeGuards';

import { TCagingSettingNumberField } from '@/store/slices/experimentRunDesign';

import { TSettingsValidationConfig } from '@/pages/experiment-run-design/CagingSettings/components/EditSettings/types';
import { getValidationInfo } from '@/pages/experiment-run-design/CagingSettings/components/EditSettings/helpers';
import NumberInput from '@/components/common/NumberInput';
import { CagingSettingsItem } from '@/graphql/API';

import styles from './NumberField.module.scss';

const cn = classnames.bind({ ...styles });

type TNumberField = {
  onChangeNumberField: (field: TCagingSettingNumberField) => (n: number) => void;
  field: TCagingSettingNumberField;
  defaultValue?: number;
  disabled?: boolean;
  validationConfig?: Nullable<Record<TCagingSettingNumberField, TSettingsValidationConfig>>;
  errors: Partial<Record<string | number, string>>;
  settings?: Nullable<CagingSettingsItem>;
  className?: string;
  customPopoverContent?: ReactNode;
  inputContainerClassName?: string;
  isMixed?: boolean;
  valueClassName?: string;
};

const NumberField: FC<TNumberField> = ({
  field,
  onChangeNumberField,
  disabled,
  defaultValue,
  validationConfig,
  errors,
  settings,
  className,
  customPopoverContent,
  inputContainerClassName,
  isMixed,
  valueClassName,
}) => {
  const [isInputPopoverOpen, setIsInputPopoverOpen] = useState(false);
  const popoverButtonRef = useRef<HTMLDivElement>(null);

  const inputValidationInfo = useMemo(() => getValidationInfo(validationConfig?.[field]), [validationConfig]);

  const toggleInputPopover = useCallback(() => {
    setIsInputPopoverOpen((prev) => !prev);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setIsInputPopoverOpen(false);
  }, []);

  return (
    <TinyPopover
      isOpen={isInputPopoverOpen}
      onClickOutside={handlePopoverClose}
      clickOutsideCapture
      positions={['bottom']}
      align="start"
      ref={popoverButtonRef}
      padding={5}
      content={
        <div
          className={cn('number-field__input-container', inputContainerClassName)}
          style={
            {
              '--container-width': `${Math.ceil(popoverButtonRef?.current?.getBoundingClientRect()?.width ?? 145)}px`,
            } as CSSProperties
          }
        >
          {customPopoverContent || (
            <>
              <div className={cn('number-field__description')}>
                {validationConfig?.[field].placeholder ? `${validationConfig?.[field].placeholder}:` : ''}
              </div>
              <span>{inputValidationInfo}</span>
              <span> {validationConfig?.[field].valueType}</span>
            </>
          )}
          <div {...addTooltip(errors[field])}>
            <NumberInput
              onChange={onChangeNumberField(field)}
              value={settings?.[field] ?? 0}
              className={cn('number-field__input', {
                error: errors[field],
              })}
              min={-1000}
              max={10000}
              disabled={disabled}
            />
          </div>
        </div>
      }
    >
      <div
        onClick={toggleInputPopover}
        role="presentation"
        className={cn(
          'number-field',
          {
            'number-field_wide': field === 'cageWallToOtherObjsMinDistance',
            'number-field_changed': (isDefined(defaultValue) && defaultValue !== settings?.[field]) || isMixed,
            error: errors[field],
          },
          className
        )}
      >
        {isMixed ? (
          <>
            <div className={cn('number-field__value', valueClassName)} {...addTooltip(errors[field])}>
              Mixed
            </div>
            <div className={cn('number-field__info-icon')}>
              <icons.PencilIcon width={22} height={15} />
            </div>
          </>
        ) : (
          <>
            <div className={cn('number-field__value', valueClassName)} {...addTooltip(errors[field])}>
              {settings?.[field] ?? 0}
            </div>
            <icons.ArrowDropdownIcon
              className={cn('number-field__dropdown-arrow', {
                'data-field__dropdown-arrow_opened': isInputPopoverOpen,
              })}
            />
          </>
        )}
      </div>
    </TinyPopover>
  );
};

export default NumberField;
