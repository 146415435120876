import { isComponentWithScan } from '@/helpers/runDesigns/typeGuards';

import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';

import { getInitialScanComponent } from '@/pages/experiment-run-design/DesignTimeline/reducer/helpers/getInitialComponent';
import { EScanActionList, TScanReducerAction, TTimeChangePayload } from './types';
import copyState from './helpers/copyState';
import getInitialScanConfig from './helpers/getInitialScanConfig';
import updateTimeValue from './helpers/updateTimeValue';
import getCurrentComponentIndex from './helpers/getCurrentComponentIndex';

const updateNumberOfScans = (state: TRunDesignComponent[], placementIndex: number, numberOfScans: number) => {
  let newState = copyState(state, structuredClone);
  const currentComponent = newState[placementIndex];

  if (!isComponentWithScan(currentComponent)) {
    return newState;
  }

  currentComponent.scanConfig ??= getInitialScanConfig();
  const { scanConfig } = currentComponent;
  if (!scanConfig) {
    return state;
  }

  if (numberOfScans < currentComponent.scanConfig.numberOfScans) {
    // delete scans
    const relativeScanIdList = currentComponent.scanConfig?.scanIds.slice(numberOfScans);
    relativeScanIdList?.forEach((scanId) => {
      const scanPlacementIndex = newState.findIndex(({ id }) => id === scanId);

      newState = newState.toSpliced(scanPlacementIndex, 1);
    });

    currentComponent.scanConfig.scanIds = currentComponent.scanConfig.scanIds.slice(0, numberOfScans);
  }

  if (numberOfScans > currentComponent.scanConfig.numberOfScans) {
    // add scans
    const countScansToAdd = numberOfScans - currentComponent.scanConfig.numberOfScans;
    for (let i = 0; i < countScansToAdd; i++) {
      const relativeToId =
        currentComponent.scanConfig.scanIds.length === 0
          ? currentComponent.id
          : currentComponent.scanConfig.scanIds.at(-1);

      const relativeScanComponent = getInitialScanComponent(currentComponent.id, relativeToId);
      currentComponent.scanConfig.scanIds.push(relativeScanComponent.id);

      newState.push(relativeScanComponent);
    }
  }

  currentComponent.scanConfig = {
    ...scanConfig,
    numberOfScans,
  };

  return newState;
};
const updateInterval = (state: TRunDesignComponent[], placementIndex: number, payload: TTimeChangePayload) => {
  const newState = copyState(state);
  const currentComponent = newState[placementIndex];

  if (!isComponentWithScan(currentComponent)) {
    return newState;
  }

  currentComponent.scanConfig ??= getInitialScanConfig();
  const { scanConfig } = currentComponent;

  if (!scanConfig) {
    return state;
  }

  currentComponent.scanConfig = {
    ...scanConfig,
    scanEvery: updateTimeValue(scanConfig?.scanEvery ?? 0, payload),
  };

  return newState;
};
const updateFirstScanStart = (state: TRunDesignComponent[], placementIndex: number, payload: TTimeChangePayload) => {
  const newState = copyState(state);
  const currentComponent = newState[placementIndex];

  if (!isComponentWithScan(currentComponent)) {
    return newState;
  }

  currentComponent.scanConfig ??= getInitialScanConfig();
  const { scanConfig } = currentComponent;

  if (!scanConfig) {
    return state;
  }

  currentComponent.scanConfig = {
    ...scanConfig,
    lagTime: updateTimeValue(scanConfig?.lagTime ?? 0, payload),
  };

  return newState;
};

const scanReducer = (state: TRunDesignComponent[], action: TScanReducerAction) => {
  const placementIndex = getCurrentComponentIndex(state, action.payload.id);

  if (action.type === EScanActionList.updateNumberOfScans) {
    return updateNumberOfScans(state, placementIndex, action.payload.value);
  }

  if (action.type === EScanActionList.updateInterval) {
    return updateInterval(state, placementIndex, action.payload);
  }

  if (action.type === EScanActionList.updateFirstScanStart) {
    return updateFirstScanStart(state, placementIndex, action.payload);
  }

  throw new Error('Unknown edit scan action.');
};

export default scanReducer;
