import { ChangeEvent, forwardRef, KeyboardEvent, MutableRefObject, RefObject, useEffect } from 'react';
import classnames from 'classnames/bind';

import styles from './Textarea.module.scss';

const cn = classnames.bind(styles);

type TextAreaProps = {
  value?: string;
  onChange?: VoidFunction | ((_e: ChangeEvent<HTMLTextAreaElement>) => void);
  disabled?: boolean;
  theme?: 'light';
  id?: string;
  isAutoFocus?: boolean;
  isEnterEnabled?: boolean;
  maxLength?: number;
  rows?: number;
  error?: string;
  wrapperClassName?: string;
  className?: string;
  placeholder?: string;
  name?: string;
};

const Textarea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      value,
      onChange,
      disabled = false,
      theme = 'light',
      id,
      isAutoFocus = false,
      isEnterEnabled = true,
      maxLength = 256,
      rows = 1,
      error,
      className,
      wrapperClassName,
      placeholder,
      name,
    },
    ref
  ) => {
    const textareaRef = ref as MutableRefObject<HTMLTextAreaElement>;

    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && !isEnterEnabled) {
        e.preventDefault();
      }
    };

    useEffect(() => {
      if (textareaRef?.current) {
        textareaRef.current.style.height = 'auto';
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    }, [value]);

    useEffect(() => {
      if (textareaRef?.current && isAutoFocus) {
        textareaRef.current.focus();
      }
    }, [textareaRef]);

    return (
      <div className={cn('textarea-wrapper', wrapperClassName)}>
        <textarea
          ref={textareaRef as RefObject<HTMLTextAreaElement>}
          value={value}
          onChange={onChange}
          className={cn('textarea', theme, className, { 'textarea_is-error': !!error })}
          maxLength={maxLength}
          rows={rows}
          disabled={disabled}
          id={id}
          onKeyDown={handleKeyDown}
          name={name ?? 'textarea'}
          placeholder={placeholder}
        />
        {!!error && <small className={cn('textarea__error')}>{error}</small>}
      </div>
    );
  }
);

Textarea.displayName = 'Textarea';

export default Textarea;
