import { FC, ReactNode, CSSProperties } from 'react';
import classnames from 'classnames/bind';
import styles from './WaveChannel.module.scss';

const cn = classnames.bind(styles);

interface IWaveChannelGroupProps {
  children: ReactNode;
  className?: string;
}

interface IWaveChannelItemProps {
  children: ReactNode;
  className?: string;
  color?: string;
}

const WaveChannelGroup: FC<IWaveChannelGroupProps> = ({ children, className }) => (
  <div className={cn('wave-channel-group', className)}>{children}</div>
);

const WaveChannelItem: FC<IWaveChannelItemProps> = ({ children, className, color }) => (
  <div className={cn('wave-channel-item', className)} style={{ '--wave-channel-color': color } as CSSProperties}>
    {children}
  </div>
);

interface IWaveChannelComposition {
  Group: typeof WaveChannelGroup;
  Item: typeof WaveChannelItem;
}

const WaveChannel: IWaveChannelComposition = {
  Group: WaveChannelGroup,
  Item: WaveChannelItem,
};

export default WaveChannel;
