import { CellIndex } from '@/graphql/API';
import classnames from 'classnames/bind';
import { TRunDesignConsumable } from '@/store/slices/experimentRunDesign';
import { isKeyOf } from '@/helpers';
import useLayoutZoom from '@/components/Layout/LayoutEmpty/useLayoutZoom';
import { MouseEvent } from 'react';
import { CSSProperty } from '@/helpers/interfaces';
import { isCompoundConsumable, isReagentConsumable, isStainConsumable } from '@/helpers/runDesigns/typeGuards';
import ReagentWaves from '@/components/runDesign/ConsumableComponent/ConsumableWaves';
import styles from './PopoverContent.module.scss';

const cn = classnames.bind(styles);

type TReSelectablePopoverContentProps<T> = {
  width: number;
  otherOptionsList: T[];
  onOptionClick: (option: T) => void;
};

const isCellIndex = (option: CellIndex | TRunDesignConsumable): option is CellIndex => !isKeyOf('type', option);

const PopoverContent = <T extends CellIndex | TRunDesignConsumable>({
  width,
  otherOptionsList,
  onOptionClick,
}: TReSelectablePopoverContentProps<T>) => {
  const layoutZoom = useLayoutZoom();

  const handleConsumableClickFactory = (consumable: T) => (ev: MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();
    onOptionClick(consumable);
  };

  return (
    <ul
      className={cn('re-selectable-popover')}
      style={{ '--layout-zoom': layoutZoom, '--width': width } as CSSProperty}
    >
      {otherOptionsList.map((consumable) => {
        const opticalDisplay =
          !isCellIndex(consumable) && (isReagentConsumable(consumable) || isStainConsumable(consumable))
            ? consumable?.opticalDisplay
            : null;

        return (
          <li className={cn('re-selectable-popover__elem')} key={consumable.id}>
            <button className={cn('re-selectable-popover__button')} onClick={handleConsumableClickFactory(consumable)}>
              <span className={cn('re-selectable-popover__name')}>{consumable?.name}</span>
              {!isCellIndex(consumable) && consumable?.name && !isCompoundConsumable(consumable) && (
                <ReagentWaves className={cn('re-selectable-popover__waves')} opticalDisplay={opticalDisplay} />
              )}
            </button>
          </li>
        );
      })}

      {!otherOptionsList.length && <div className={cn('re-selectable-popover__no-data')}>No data</div>}
    </ul>
  );
};

export default PopoverContent;
