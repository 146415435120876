import { FC, MouseEventHandler } from 'react';
import classnames from 'classnames/bind';

import { CellIndex } from '@/graphql/API';
import ReSelectable from '@/pages/experiment-run-design/SampleInformation/components/EditCellType/components/ReSelectable';
import icons from '@/components/common/icons';
import AddToTableButton from '../AddToTableButton';

import styles from './Consumable.module.scss';

const cn = classnames.bind(styles);

type TPreSelectedCellTypeName = {
  className?: string;
  onClick?: (cellIndex: CellIndex) => void;
  onPlusClick?: MouseEventHandler<HTMLButtonElement>;
  otherLaneCellIndexList: CellIndex[];
  isSelectionHidden?: boolean;
};

const PreSelectedCellTypeName: FC<TPreSelectedCellTypeName> = ({
  className,
  onClick,
  onPlusClick,
  otherLaneCellIndexList,
  isSelectionHidden = false,
}) => {
  const handleOptionClick = (newCellIndex: CellIndex) => {
    onClick?.(newCellIndex);
  };

  return (
    <AddToTableButton className={cn('consumable__btn', 'preselected-consumable', className)} onClick={onPlusClick}>
      <div className={cn('consumable', 'consumable_preselected')}>
        <ReSelectable
          otherOptionsList={otherLaneCellIndexList}
          onOptionClick={handleOptionClick}
          isSelectionHidden={isSelectionHidden}
        >
          <div className={cn('preselected-consumable__name')}>
            <icons.PlusIcon className={cn('add-button__plus-icon')} />
            <span className={cn('consumable__name')}>Add cell</span>
          </div>
        </ReSelectable>
      </div>
    </AddToTableButton>
  );
};

export default PreSelectedCellTypeName;
