import { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames/bind';

import { isCompoundConsumable, isReagentConsumable, isStainConsumable } from '@/helpers/runDesigns/typeGuards';

import Removable from '@/pages/experiment-run-design/SampleInformation/components/EditCellType/components/Removable';
import { TRunDesignConsumable } from '@/store/slices/experimentRunDesign';
import ReSelectable from '@/pages/experiment-run-design/SampleInformation/components/EditCellType/components/ReSelectable';
import styles from './Consumable.module.scss';
import ReagentWaves from './ConsumableWaves';

const cn = classNames.bind(styles);

type TConsumableComponent = {
  consumable: TRunDesignConsumable;
  notSameLaneConsumableList?: TRunDesignConsumable[];
  className?: string;
  wavesClassName?: string;
  onClick?: Nullable<(consumable: TRunDesignConsumable) => void>;
  withWaves?: boolean;
  deleteReagent?: () => void;
  onPreselectedClick?: (consumable: TRunDesignConsumable) => void;
};

const ConsumableComponent: FC<TConsumableComponent> = ({
  consumable,
  onClick,
  className,
  wavesClassName,
  withWaves = true,
  deleteReagent,
  notSameLaneConsumableList,
  onPreselectedClick,
}) => {
  const opticalDisplay = useMemo(
    () => (isReagentConsumable(consumable) || isStainConsumable(consumable) ? consumable?.opticalDisplay : null),
    [consumable]
  );

  const removeReagent = () => {
    deleteReagent?.();
  };

  const handleClick = useCallback(() => {
    onClick?.(consumable);
  }, [onClick, consumable]);

  const handleConsumableReselect = (newConsumable: TRunDesignConsumable) => {
    onPreselectedClick?.(newConsumable);
  };

  return (
    <>
      {onClick && (
        <button onClick={handleClick} className={cn('consumable-wrap', className)}>
          <Removable onRemove={removeReagent} className={cn('consumable')}>
            <ReSelectable otherOptionsList={notSameLaneConsumableList ?? []} onOptionClick={handleConsumableReselect}>
              <div className={cn('consumable__content')}>
                <span className={cn('consumable__name')}>{consumable?.name}</span>
                {withWaves && consumable?.name && !isCompoundConsumable(consumable) && (
                  <ReagentWaves className={cn('consumable__waves', wavesClassName)} opticalDisplay={opticalDisplay} />
                )}
              </div>
            </ReSelectable>
          </Removable>
        </button>
      )}
      {!onClick && (
        <div className={cn('consumable-wrap', className)}>
          <div className={cn('consumable')}>
            <div className={cn('consumable__content')}>
              <span className={cn('consumable__name')}>{consumable?.name}</span>
              {withWaves && consumable?.name && !isCompoundConsumable(consumable) && (
                <ReagentWaves className={cn('consumable__waves', wavesClassName)} opticalDisplay={opticalDisplay} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConsumableComponent;
