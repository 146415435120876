import { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Tab, TabList, Tabs, TabPanel } from 'react-tabs';
import { DndProvider } from 'react-dnd';
import { MultiBackend } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import classnames from 'classnames/bind';

import { EPageWithChartType } from '@/types/charts';
import { EJobRunType } from '@/types/jobRuns';

import { getScatterPlotObjectLevelAxesOptions } from '@/helpers/channels';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import useFindSucceededJobRuns from '@/hooks/jobRuns/useFindSucceededJobRuns';
import { useChartWithGatesDefaultProps } from '@/hooks/useChartWithGatesDefaultProps';
import useParamsProjectId from '@/hooks/useParamsProjectId';
import { EExperimentTabId, useLinks } from '@/hooks/useLinks';

import { experimentSelectors } from '@/store/slices/experiment';
import { headerActions } from '@/store/slices/header';
import { violinActions } from '@/store/slices/violin';
import { chartSettingsActions, chartSettingsSelectors } from '@/store/slices/chartSettings';

import { TExperimentDataProps, withExperimentData } from '@/hoc/withExperimentData';
import { withExperimentToken } from '@/hoc/withExperimentToken';
import { withChartData } from '@/hoc/withChartData';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { ExperimentModalsProvider } from '@/contexts/ExperimentModalsContext';

import ControlPanel from '@/components/Layout/ControlPanel';
import Range from '@/components/common/Range';
import ExperimentPageHeader from '@/components/common/ExperimentPageHeader';
import ExperimentPageDefaultHeaderControls from '@/components/common/ExperimentPageHeader/ExperimentPageDefaultHeaderControls';
import SingleChartWithGates from '@/components/charts/SingleChartWithGates';
import ChartSettingsButton from '@/components/charts/ChartSettingsButton';
import DownloadMatrixButton from '@/components/charts/DownloadChartButton/DownloadMatrixButton';

import GateList from './components/Scatterplot/components/GateList';
import Scatterplot from './components/Scatterplot';
import MultiHistogram from './MultiHistogram';
import Heatmap from './Heatmap';
import ViolinGraph from './ViolinGraph';
import ManageDatasetListButton from './ManageDatasetListButton';

import styles from './Analytics.module.scss';

const cn = classnames.bind(styles);

enum EAnalyticsTab {
  scatterPlot = 0,
  multiHistogram = 1,
  heatmap = 2,
  violinGraph = 3,
}

const MAX_CHART_COLUMNS = 4;
const MIN_CHART_COLUMNS = 2;
const INVERTOR = MAX_CHART_COLUMNS + MIN_CHART_COLUMNS;
const DEFAULT_COLUMNS_COUNT = 2;

const Analytics = ({ experimentId }: TExperimentDataProps) => {
  const appDispatch = useAppDispatch();
  const { generateExperimentLink } = useLinks();
  const chartId = usePlotChartIdContext();

  const currentAppScan = useSelector(experimentSelectors.selectCurrentScan);
  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const projectId = useParamsProjectId();
  const { allProps, ...componentsProps } = useChartWithGatesDefaultProps({});
  const columnsCount = localStorage.getItem('columnsCount');
  const initialChartColumns = columnsCount ? Number(columnsCount) : DEFAULT_COLUMNS_COUNT;

  const isCageLvlForced = useSelector(chartSettingsSelectors.selectIsCageLvlForced(chartId));
  const { optionList: cageLevelMultiLanesAxesOptionList } = useSelector(
    chartSettingsSelectors.selectMultiLanesAxesOptionList(
      allProps.chartDataList,
      isObjectEntityEnabled && !isCageLvlForced
    )
  );

  const [chartColumns, setChartColumns] = useState(initialChartColumns);
  const [currentTab, setCurrentTab] = useState(EAnalyticsTab.scatterPlot);

  const experimentData = useSelector(experimentSelectors.selectCurrentExperiment);

  const { laneIdList, hasSucceededJobRun: hasSucceededSeuratJobRun } = useFindSucceededJobRuns(
    EJobRunType.seuratGeneration,
    experimentId,
    experimentData?.organizationId
  );

  const totalCages = useMemo(
    () => experimentData?.numberOfCages ?? currentAppScan?.summary.numberOfCages,
    [currentAppScan?.summary, experimentData?.numberOfCages]
  );

  const scatterPlotAxesOptions = useMemo(
    () =>
      isObjectEntityEnabled
        ? allProps.chartDataList.flatMap((chartData) => getScatterPlotObjectLevelAxesOptions(chartData.channelList))
        : cageLevelMultiLanesAxesOptionList,
    [isObjectEntityEnabled, allProps.chartDataList, cageLevelMultiLanesAxesOptionList]
  );

  const handleColumnsCountChange = useCallback((value: number | number[]) => {
    const newColumnsCount = INVERTOR - Number(value);
    setChartColumns(newColumnsCount);
    localStorage.setItem('columnsCount', newColumnsCount.toString());
  }, []);

  useEffect(
    () => () => {
      appDispatch(chartSettingsActions.clearRanges());
    },
    []
  );

  useEffect(() => {
    if (localStorage.getItem('columnsCount')) {
      return;
    }
    localStorage.setItem('columnsCount', DEFAULT_COLUMNS_COUNT.toString());
  }, []);

  useEffect(() => {
    appDispatch(headerActions.removeAllLinks());

    if (projectId) {
      appDispatch(headerActions.setNewLink({ title: 'projects', link: `/projects` }));
      appDispatch(headerActions.setNewLink({ title: 'project', link: `/project/${projectId}` }));
    } else {
      appDispatch(headerActions.setNewLink({ title: 'dashboard', link: '/' }));
    }

    appDispatch(
      headerActions.setNewLink({
        title: 'experiment',
        link: generateExperimentLink(EExperimentTabId.data),
      })
    );
  }, [projectId]);

  useEffect(() => {
    appDispatch(violinActions.setLaneIds(laneIdList));
  }, [laneIdList]);

  return (
    <ExperimentModalsProvider>
      <main className={cn('analytics')}>
        <ExperimentPageHeader
          title={experimentData?.name ?? ''}
          experimentData={experimentData}
          totalCages={totalCages}
          isBasicInfoHidden
          experimentControls={
            <ExperimentPageDefaultHeaderControls
              buttonClassName={cn('analytics__navigator-button')}
              selectClassName={cn('analytics__select')}
            />
          }
        />

        <div className={cn('analytics__tabs')}>
          <Tabs
            selectedTabClassName={cn('tabs__item_selected')}
            className={cn('tabs__container')}
            selectedIndex={currentTab}
            onSelect={setCurrentTab}
          >
            <ControlPanel>
              <ControlPanel.StickyContent>
                <TabList className={cn('tabs')}>
                  <Tab className={cn('tabs__item')} value={EAnalyticsTab.scatterPlot}>
                    Matrix view
                  </Tab>
                  <Tab className={cn('tabs__item')} value={EAnalyticsTab.multiHistogram}>
                    Multi histogram
                  </Tab>
                  <Tab className={cn('tabs__item')} value={EAnalyticsTab.multiHistogram}>
                    Heatmap
                  </Tab>
                  {hasSucceededSeuratJobRun && (
                    <Tab className={cn('tabs__item')} value={EAnalyticsTab.violinGraph}>
                      Violin Graph
                    </Tab>
                  )}
                </TabList>
              </ControlPanel.StickyContent>
              {currentTab === EAnalyticsTab.scatterPlot && (
                <ControlPanel.RightActions>
                  <ManageDatasetListButton />
                  <ChartSettingsButton
                    pageType={EPageWithChartType.matrixView}
                    scatterPlotAxesOptions={scatterPlotAxesOptions}
                  />
                  <DownloadMatrixButton />
                  <Range
                    onChange={handleColumnsCountChange}
                    min={MIN_CHART_COLUMNS}
                    max={MAX_CHART_COLUMNS}
                    step={1}
                    value={INVERTOR - chartColumns}
                    labelBlockClassName={cn('analytics__range')}
                    infoBlockClassName={cn('analytics__range-info')}
                    sliderValueClassName={cn('analytics__range-slider-value')}
                    rangeHeight={2}
                  />
                </ControlPanel.RightActions>
              )}
            </ControlPanel>
            <TabPanel
              className={cn('tabs__panel', {
                tabs__panel_inactive: currentTab !== EAnalyticsTab.scatterPlot,
              })}
            >
              {experimentId && (
                <DndProvider backend={MultiBackend} options={HTML5toTouch}>
                  <Scatterplot chartColumns={chartColumns} {...allProps} />
                </DndProvider>
              )}
            </TabPanel>
            <TabPanel
              className={cn('tabs__panel', {
                tabs__panel_inactive: currentTab !== EAnalyticsTab.multiHistogram,
              })}
            >
              {experimentId && (
                <SingleChartWithGates
                  isMulti
                  ChartComponent={MultiHistogram}
                  GatesComponent={GateList}
                  pageType={EPageWithChartType.multiHistogram}
                  {...componentsProps}
                />
              )}
            </TabPanel>
            <TabPanel
              className={cn('tabs__panel', {
                tabs__panel_inactive: currentTab !== EAnalyticsTab.heatmap,
              })}
            >
              {experimentId && (
                <SingleChartWithGates
                  isHeatmap
                  ChartComponent={Heatmap}
                  GatesComponent={GateList}
                  pageType={EPageWithChartType.heatmap}
                  {...componentsProps}
                />
              )}
            </TabPanel>

            {hasSucceededSeuratJobRun && (
              <TabPanel
                className={cn('tabs__panel', {
                  tabs__panel_inactive: currentTab !== EAnalyticsTab.violinGraph,
                })}
              >
                <SingleChartWithGates
                  ChartComponent={ViolinGraph}
                  GatesComponent={GateList}
                  pageType={EPageWithChartType.violin}
                  {...componentsProps}
                />
              </TabPanel>
            )}
          </Tabs>
        </div>
      </main>
    </ExperimentModalsProvider>
  );
};

export default withExperimentToken(withExperimentData(withChartData(Analytics)));
