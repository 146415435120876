import { FC, KeyboardEventHandler, MouseEventHandler, ReactNode, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import icons from '@/components/common/icons';
import { Popover as TinyPopover } from 'react-tiny-popover';
import { TRunDesignConsumable } from '@/store/slices/experimentRunDesign';
import { CellIndex } from '@/graphql/API';
import { includes } from '@/helpers/enum';
import styles from './ReSelectable.module.scss';
import PopoverContent from './PopoverContent';

const cn = classNames.bind(styles);

type TReSelectableProps = {
  children: ReactNode;
  className?: string;
  wrapClassName?: string;
  triggerClassName?: string;
  isSelectionHidden?: boolean;
} & (
  | {
      otherOptionsList: TRunDesignConsumable[];
      onOptionClick: (option: TRunDesignConsumable) => void;
    }
  | {
      otherOptionsList: CellIndex[];
      onOptionClick: (option: CellIndex) => void;
    }
);

const ReSelectable: FC<TReSelectableProps> = ({
  children,
  className,
  wrapClassName,
  triggerClassName,
  otherOptionsList,
  onOptionClick,
  isSelectionHidden = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [width, setWidth] = useState(0);
  const rootRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleTriggerClick: MouseEventHandler<HTMLDivElement> = (ev) => {
    ev.stopPropagation();
    setIsOpen((prev) => !prev);

    if (!rootRef.current) {
      return;
    }

    setWidth(rootRef.current.clientWidth);
  };

  const handleOptionClick = (option: any) => {
    handleClose();
    onOptionClick(option);
  };

  const handleTriggerKeyDown: KeyboardEventHandler<HTMLDivElement> = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();

    if (includes(['Space', 'Enter'], ev.code)) {
      setIsOpen((prev) => !prev);
    }
  };

  return (
    <div
      className={cn('re-selectable', { '_select-open': isOpen, '_with-selection': !isSelectionHidden }, className)}
      ref={rootRef}
    >
      <div className={cn('re-selectable__wrap', wrapClassName)}>{children}</div>
      {!isSelectionHidden && (
        <TinyPopover
          isOpen={isOpen}
          align="end"
          content={
            <PopoverContent otherOptionsList={otherOptionsList} onOptionClick={handleOptionClick} width={width} />
          }
          positions={['bottom', 'top']}
          onClickOutside={handleClose}
          clickOutsideCapture
          padding={5}
        >
          <div
            className={cn('re-selectable__select-trigger', triggerClassName)}
            onClick={handleTriggerClick}
            aria-label="Select dropdown trigger"
            role="button"
            tabIndex={0}
            onKeyDown={handleTriggerKeyDown}
          >
            <icons.ArrowDropdownIcon />
          </div>
        </TinyPopover>
      )}
    </div>
  );
};

export default ReSelectable;
