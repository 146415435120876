import { RefObject, useMemo, useRef } from 'react';

import {
  CLIPBOARD_CLASS_NAME,
  COPIED_CLASS_NAME,
  SELECTED_CLASS_NAME,
  TSelectableData,
} from '@/helpers/reactSelectable';

import { getClipboardItems, getItemsData, getSelectedItems, handleAllItemAction, handleCellPaste } from '../helpers';

type TUseCopyPaste<T> = {
  tableRef: RefObject<HTMLDivElement>;
  tableData: T;
  setTableData?: (data: T) => void;
};

function useCopyPaste<T>({ tableRef, tableData, setTableData }: TUseCopyPaste<T>) {
  const tableDataRef = useRef<T>(tableData);
  const copyDataRef = useRef<TSelectableData[][]>([]);

  const onCopyAction = () => {
    const selectedItems = getSelectedItems(tableRef.current);
    tableDataRef.current = tableData;

    if (!selectedItems.length) {
      return;
    }

    handleAllItemAction(tableRef.current, (el) => {
      if (el.classList.contains(SELECTED_CLASS_NAME)) {
        el.classList.remove(SELECTED_CLASS_NAME);
        el.classList.add(COPIED_CLASS_NAME);
        el.classList.add(CLIPBOARD_CLASS_NAME);
        return;
      }

      el.classList.remove(SELECTED_CLASS_NAME);
      el.classList.remove(COPIED_CLASS_NAME);
      el.classList.remove(CLIPBOARD_CLASS_NAME);
    });

    copyDataRef.current = getItemsData(getClipboardItems(tableRef.current));
  };

  const onPasteAction = () => {
    const conditions = [tableDataRef.current, setTableData];

    if (!conditions.every(Boolean)) {
      return null;
    }

    const selectedItemsData = structuredClone(getItemsData(getSelectedItems(tableRef.current)));
    const clipboardItems = structuredClone(copyDataRef.current);

    let isIncorrectCopyPaste = true;

    const tableDataCopy = structuredClone(tableDataRef.current);
    const newTableData = structuredClone(tableData);
    if (!Array.isArray(newTableData)) {
      // toast.error('Incorrect copy and paste');
      return;
    }

    const isArrayFormatData = newTableData.some((el) => Array.isArray(el));

    selectedItemsData.forEach((rowData, rowSelectedIndex) => {
      const correctIndex = rowSelectedIndex % clipboardItems.length;
      const fromCopy = clipboardItems[correctIndex];

      if (!fromCopy || !rowData) {
        return;
      }

      rowData.forEach(
        handleCellPaste({
          fromCopy,
          isArrayFormatData,
          newTableData,
          tableDataCopy,
          callBackOnFindEl: () => {
            isIncorrectCopyPaste = false;
          },
        })
      );
    });

    if (isIncorrectCopyPaste) {
      // toast.error('Incorrect copy and paste');
      return;
    }

    handleAllItemAction(tableRef.current, (el) => {
      el.classList.remove(COPIED_CLASS_NAME);
    });

    setTableData?.(newTableData);
  };

  return useMemo(() => ({ onCopyAction, onPasteAction }), [onCopyAction, onPasteAction]);
}

export default useCopyPaste;
