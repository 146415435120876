import { FC, ReactNode, ChangeEventHandler, useId, useState, useEffect } from 'react';
import classnames from 'classnames/bind';

import styles from './RadioButton.module.scss';

const cn = classnames.bind(styles);

export type TRadioButton = {
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  id?: string;
  label?: ReactNode;
  theme?: 'dark' | 'light';
  name?: string;
  value?: string;
};

const RadioButton: FC<TRadioButton> = ({
  checked: outerChecked = false,
  onChange,
  disabled = false,
  readOnly = false,
  className = '',
  label,
  id: outerId,
  theme = 'dark',
  name,
  value,
}) => {
  const localId = useId();
  const [id, setId] = useState(localId);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(outerChecked);
  }, [outerChecked]);

  useEffect(() => {
    if (!outerId) {
      return;
    }

    setId(outerId);
  }, [outerId]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (ev) => {
    setChecked(ev.target.checked);
    onChange?.(ev);
  };

  return (
    <label
      htmlFor={id}
      className={cn(
        'radio__label',
        `radio_${theme}`,
        {
          radio_disabled: disabled,
          radio_checked: checked,
        },
        className
      )}
    >
      <input
        type="radio"
        className={cn('radio__input')}
        onChange={handleChange}
        disabled={disabled}
        checked={checked}
        readOnly={readOnly}
        id={id}
        name={name}
        value={value}
      />
      {label}
    </label>
  );
};

export default RadioButton;
