import { Reducer, useReducer } from 'react';
import { isDefined, isObjectKey } from '@/helpers/typeGuards';

const EFilterActionList = {
  setState: 'filter/setState',
  updateStringState: 'filter/updateStringState',
} as const;

const initialFilterState = {
  vendor: '',
  catalogIndex: '',
  ex: '',
  em: '',
};

export type TFilterState = Record<keyof typeof initialFilterState, string>;

type TSetState = {
  type: typeof EFilterActionList.setState;
  payload: TFilterState;
};

type TUpdateStringState = {
  type: typeof EFilterActionList.updateStringState;
  payload: {
    field: string;
    value: string;
  };
};

type TFilterStateAction = TSetState | TUpdateStringState;

const filterStateReducer = (state: TFilterState, action: TFilterStateAction) => {
  if (action.type === EFilterActionList.setState) {
    return action.payload;
  }

  if (action.type === EFilterActionList.updateStringState) {
    return {
      ...state,
      [action.payload.field]: action.payload.value,
    };
  }

  return state;
};

const useReagentFilter = () => {
  const [filterState, dispatchFilterState] = useReducer<Reducer<TFilterState, TFilterStateAction>>(
    filterStateReducer,
    initialFilterState
  );

  const cleanFilters = () => {
    dispatchFilterState({ type: EFilterActionList.setState, payload: initialFilterState });
  };

  return {
    filterState,
    updateFactory: (fieldName: string) => {
      const updateField = (value: string, field: string) => {
        dispatchFilterState({
          type: EFilterActionList.updateStringState,
          payload: {
            field,
            value,
          },
        });
      };

      const updateMethodsMap: Partial<Record<keyof TFilterState, (value: string) => void>> = Object.keys(
        initialFilterState
      ).reduce(
        (acc, field) => ({
          ...acc,
          [field]: (value: string) => {
            updateField(value, field);
          },
        }),
        {}
      );

      if (!isObjectKey(fieldName, updateMethodsMap)) {
        return () => null;
      }

      const method = updateMethodsMap[fieldName];

      if (isDefined(method)) {
        return method;
      }

      return () => null;
    },
    cleanFilters,
    cssGridLayout: 'repeat(2, 1fr) repeat(2, 130px)',
  };
};

export default useReagentFilter;
