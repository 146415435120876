import { components, SingleValueProps } from 'react-select';
import classNames from 'classnames/bind';
import { useMemo } from 'react';

import { TChannelOption } from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/ChannelSelector/ChannelOption';

import WaveChannel from '@/components/common/WaveChannel';

import styles from './SingleValue.module.scss';

const cn = classNames.bind(styles);

const FilterSingleValue = (props: SingleValueProps<TChannelOption>) => {
  const {
    data: {
      customData: { hex, symbol, category },
    },
  } = props;

  const label = useMemo(() => (category === 'detection' ? 'EM' : 'EX'), [category]);

  return (
    <components.SingleValue {...props} className={cn('single-value', 'single-value_filter')}>
      <div className={cn('single-value__text')}>{label}</div>
      <WaveChannel.Item color={hex}>{symbol}</WaveChannel.Item>
    </components.SingleValue>
  );
};

export default FilterSingleValue;
