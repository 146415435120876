import { useMemo } from 'react';
import { components, PlaceholderProps } from 'react-select';

import classNames from 'classnames/bind';
import { TChannelOption } from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsModal/components/ChannelSelector/ChannelOption';
import { isKeyOf } from '@/helpers';
import styles from './FilterPlaceholder.module.scss';

const cn = classNames.bind(styles);

const FilterPlaceholder = (props: PlaceholderProps<TChannelOption>) => {
  const {
    options: [option],
  } = props;

  const label = useMemo(
    () =>
      isKeyOf('customData', option) && (option as TChannelOption).customData.category === 'detection' ? 'EM' : 'EX',
    [option]
  );

  return (
    <components.Placeholder {...props} className={cn('placeholder')}>
      <div className={cn('placeholder__text')}>{label}</div>
    </components.Placeholder>
  );
};

export default FilterPlaceholder;
